.table-group table tr{
	background-color: #fff !important;
}
.table-group table td{
	text-align: left;
	padding-left: 0% !important;
}

.group-button-right{
	text-align: right;
}

.group-switch-buttons {
	margin-bottom: 1rem;
	text-align: left;

	& a:first-child {
		margin-left: 1rem;
	}

	.button__secondary {
		padding-left: 1rem;
		padding-right: 1rem;
		display: inline-flex;
		align-items: center;

		&:hover {
			svg {
				fill: #ffffff;
			}
		}

		i {
			margin-left: 0.2rem;
		}

		svg {
			fill: #00a886;
			padding-top: 0.2rem;
			margin-left: 0.2rem;
			height: 1.2rem;
			width: auto;
		}
	}
}

.group-selector {
	border-bottom: 1px solid #D0D0D1;
	margin-bottom: rem(16);
}

table.manage-members {
	.button.approve {
		display: block;
		margin-top: rem(8);
		margin-bottom: 0;
		@include media($large-screen) {
			margin-top: 0;
			display: inline-block;
			margin-left: rem(30);
		}
	}
}

.group-type-badge {
	background-color: $green;
	color: $white;
	padding: 5px 10px;
	border-radius: 4px;
}

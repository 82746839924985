// Fonts
//
// Fonts used on site
//
// Markup: fonts.html
//
// Styleguide Components.Fonts

.Basetica-Black { font-family: Basetica-Black, sans-serif; }
.Basetica-BlackItalic { font-family: Basetica-BlackItalic, sans-serif; }
.Basetica-BoldItalic { font-family: Basetica-BoldItalic, sans-serif; }
.Basetica-Bold { font-family: Basetica-Bold, sans-serif; }
.Basetica-Light { font-family: Basetica-Light, sans-serif; }
.Basetica-LightItalic { font-family: Basetica-LightItalic, sans-serif; }
.Basetica-Medium { font-family: Basetica-Medium, sans-serif; }
.Basetica-Regular { font-family: Basetica-Regular, sans-serif; }
.Basetica-Italic { font-family: Basetica-Italic, sans-serif; }
.Basetica-Thin { font-family: Basetica-Thin, sans-serif; }
.Basetica-ThinItalic { font-family: Basetica-ThinItalic, sans-serif; }
.Basetica-MediumItalic { font-family: Basetica-MediumItalic, sans-serif; }

$Basetica-Black: Basetica-Black, sans-serif;
$Basetica-BlackItalic: Basetica-BlackItalic, sans-serif;
$Basetica-BoldItalic: Basetica-BoldItalic, sans-serif;
$Basetica-Bold: Basetica-Bold, sans-serif;
$Basetica-Light: Basetica-Light, sans-serif;
$Basetica-LightItalic: Basetica-LightItalic, sans-serif;
$Basetica-Medium: Basetica-Medium, sans-serif;
$Basetica-Regular: Basetica-Regular, sans-serif;
$Basetica-Italic: Basetica-Italic, sans-serif;
$Basetica-Thin: Basetica-Thin, sans-serif;
$Basetica-ThinItalic: Basetica-ThinItalic, sans-serif;
$Basetica-MediumItalic: Basetica-MediumItalic, sans-serif;
.label {
  display: inline-block;
  padding: .4em .25em .25em .25em;
  border-radius: 2px;
  &.label--dark-bg {
    background: #028e6d
  }
  &.label--fixed-width {
    width: 75%;
  }
}
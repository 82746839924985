// Tags
//
// Markup: tags.html
//
// Styleguide Components.Tags

.tag {
	@include padding(5px 7px);
	@include margin(0 0 10px 0);
	font-size: rem(12);
	line-height: rem(18);
	text-decoration: none;
	color: #5F5F5F;
	background: #e7e7e7;
	display: inline-block;

	&:hover,
	&:focus {
		color: #5F5F5F;
	}

	&.active {
		background: $green;
		color: $white;
	}

	&.empty {
		color: #A6A6A6;
		cursor: default;
		background: transparent;

		&:hover,
		&:focus {
			color: #A6A6A6;
		}
	}
}
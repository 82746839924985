.sidebar.finder {

	h1 {
		font-size: rem(36);
		line-height: rem(43);
		@include padding(0 0 22px 0);
		@include margin(0 0 20px 0);
		border-bottom: 1px solid #D0D0D1;
	}

	h2 {
		font-size: rem(23);
		line-height: rem(35);
	}

	h3 {
		text-transform: uppercase;
		font-size: rem(14);
		@extend .Basetica-Bold;
		margin-bottom: rem(10);
	}

	.filter-block {
		border-bottom: 1px solid #D0D0D1;

        @include padding(0 0 22px 0);
        @include margin(0 0 20px 0);

		&:last-of-type {
			border-bottom: none;
		}
	}

}
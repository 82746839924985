/**
Project Area
---------------------------------------------
**/

.tab-content {

    background: $mouse-white;

    @include media($medium-screen-up) {
        background: none;
    }

}

.project-access {

    padding: rem(50);

    h1 {
        font-size: rem(24);
        line-height: rem(28);

        @include media($medium-screen-up) {
            font-size: rem(36);
            line-height: rem(42);
        }


        margin-bottom: rem(25);
    }

    p {
        font-size: rem(16);
        line-height: rem(21);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(28);
        }

        margin-bottom: rem(25);
    }
}


.project-tab {
    margin-left: 0;
    margin-top: 0;

    @include media($medium-screen-up) {
        margin-top: rem(20);
        font-size: rem(20);
        margin-left: rem(30);
    }

    .modal__button {
        font-size: rem(21);
        padding-top: rem(10);

        @include media($medium-screen-up) {
            font-size: rem(18);
            padding-top: rem(12);
        }
    }

    h1 {
        font-size: rem(24);
        line-height: rem(28);

        @include media($medium-screen-up) {
            font-size: rem(36);
            line-height: rem(42);
        }


        margin-bottom: rem(25);
    }

    h2 {
        clear: both;
        @extend .Basetica-Medium;
        font-size: rem(20);
        margin-top: rem(10);

        @include media($medium-screen-up) {
            font-size: rem(26);
        }

        line-height: rem(30);
        margin-bottom: rem(25);
    }

    h3 {
        @extend .Basetica-Medium;
        font-size: rem(18);
        line-height: rem(22);

        @include media($medium-screen-up) {
            font-size: rem(22);
            line-height: rem(26);
        }
    }

    p, ol, ul {

        font-size: rem(16);
        line-height: rem(21);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(28);
        }

        margin-bottom: rem(25);
    }

    i, em {
        @extend .Basetica-Italic;
    }

    b {
        @extend .Basetica-Bold;
    }

    .project__add-work {

        margin-left: rem(-20);
        margin-right: rem(-20);

        @include media($medium-screen-up) {
            margin: 0;
        }

        .asset-image {
            margin-top: rem(10);
            width: 100%;

            @include media($medium-screen-up) {
                width: rem(673);
            }
        }

        .evidence__asset-block {
            &:first-child {
                border: 0;
                padding-top: 0;
            }
        }

        .evidence__assets {
            padding-top: rem(29);
        }

        .highlight__title {
            h2 {
                margin-bottom: rem(6);

                @include media($medium-screen-up) {
                    margin-bottom: 0;
                }
            }
        }

        .prompt__response {
            margin: 0;
            position: relative;

            &::after {
                content: '';
                background-image: url(/img/prompt-triangle.svg);
                background-repeat: no-repeat;
                background-position: center bottom;
                left: 50%;
                transform: translateX(-50%);
                height: rem(29);
                width: 100%;
                position: absolute;
                bottom: rem(-29);

            }
        }
    }

    .mobile-project-steps {
        background: $mouse-background-gray;
        margin-top: rem(-20);
        margin-left: rem(-20);
        margin-right: rem(-20);
    }

    .dropdown {
        .dropdown-button-delay {
            text-align: left;
            padding-left: rem(20);
        }
    }

    .project-tab__heading {

        width: 100%;

        @include media($large-screen-up) {
            width: rem(935);
        }

        @media screen and (min-width: rem(1115)) {
            width: rem(1045);
        }

        @media screen and (min-width: rem(1153)) {
            width: rem(1085);
        }

        @media screen and (min-width: rem(1315)) {
            width: rem(1245);
        }

        h1 {
            display: inline-block;
        }

        .switch {
            font-size: rem(11);
            float: right;
            line-height: rem(13);
            color: #5F5F5F;

            #facilitator_note_toggle {
                margin-left: rem(5);
            }

            span {
                margin-right: rem(7);
            }
        }
    }

    .switch__desktop {
        display: none;
        @include media($large-screen-up) {
            display: block;
        }
    }


    .switch__mobile {
        display: block;
        text-align: center;

        @include media($large-screen-up) {
            display: none;
        }

        .switch {
            font-size: rem(11);
            color: #5F5F5F;
        }
    }

    .project__assets-none {
        background-color: $mouse-white;
        padding: rem(50) 0;
        text-align: center;

        @include media($medium-screen-up) {
            padding: rem(46) 0;
        }

        .no-asset-text {
            color: $mouse-green;
            font-size: rem(20);
            line-height: rem(24);
        }

        .no-asset-icon {
            margin-bottom: rem(23);
        }
    }

    .project__step-header {
        display: flex;
        justify-content: space-between;
    }

    .current-step__content {
        margin-bottom: rem(35);

        .step__item-embed {
            margin: rem(34) auto rem(42) auto;
            text-align: center;
            position: relative;
            padding-bottom: 56.25%;
            overflow: hidden;

            iframe {
                left:0;
                top:0;
                height:100%;
                width:100%;
                position:absolute;
            }
        }

        .step__item-image-wrap {
            margin: 0 auto;
            display: inline-block;
        }

        .step__item-image {

            margin: 0 auto rem(35);
            text-align: center;

            &.full {

                @media screen and (max-width: rem(1024)) {
                    width: 100%;
                }

                // img {
                //     max-width: 100%;
                // }
            }

            &.left {
                width: 100%;

                @include media($small-screen-up) {
                    float: left;
                    margin-right: rem(25);
                    width: auto;
                    clear: both;
                }
            }

            &.right {
                width: 100%;
                text-align: right;
                @include media($small-screen-up) {
                    float: right;
                    margin-left: rem(25);
                    width: auto;
                    clear: both;
                    max-width: 40%;
                }
            }

            &.small {

                width: 100%;

                @include media($small-screen-up) {
                    width: 50%;
                }
            }

            &.medium {
                width: 100%;

                @include media($small-screen-up) {
                    width: 75%;
                }
            }

            &.automatic {


                .caption {
                    /* display: table-caption; */
                    /*caption-side: bottom; */
                }

                &.right,
                &.left {
                    @include media($small-screen-up) {
                        max-width: 40%;
                    }
                }
            }

            img {
                margin: 0 auto;
                vertical-align: middle;
                display: block;
            }

            .caption {
                font-size: rem(18);
                line-height: rem(22);
                background-color: $red;
                color: $mouse-white;
                padding: rem(13) rem(18);
                text-align: left;
            }
        }

        .ie__image {
            img {
                width: 100%;
            }
        }
    }

    .has-heading {
        margin: 0;

        h3 {
            margin-bottom: rem(12);
        }
    }

    .step-pagination {
        background-color: #CDEAE4;
        border-radius: rem(2);
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(32);
        min-width: rem(32);

        .pagination__arrow {
            fill: $mouse-green;
        }
    }

    .step-pagination--hidden {
        visibility: hidden;
    }

    .step-pagination__previous {
        margin-left: 0;

        @include media($large-screen-up) {
            margin-left: rem(-12);
        }
    }

    .step-pagination__next {
        margin-right: 0;

        @include media($large-screen-up) {
            margin-right: rem(-12);
        }
    }

    .step-footer {
        float: left;
        background-color: $mouse-white;
        padding: 0 0 rem(50) 0;
        width: 100%;

        @include media($medium-screen-up) {
            padding: 0 rem(20) rem(20) rem(20);
        }

        .step-pagination__previous, .step-pagination__next {
            margin: 0;
        }

        .evidence__asset-block:first-child {
            border: 0;
        }

        .step-footer__hr {
            margin-top: rem(24);
        }

        .evidence__asset-wrapper + .step-footer__hr {
            margin-top: rem(10);
        }
    }

    .step-footer__actions {
        display: flex;
        justify-content: space-between;
    }

    .step-header__title {
        color: $mouse-black;
        font-size: rem(16);
        line-height: rem(19);
        margin-bottom: rem(19);
        padding-left: rem(7);
        padding-right: rem(7);
        text-align: center;
        @extend .Basetica-Regular;

        @include media($medium-screen-up) {
            font-size: rem(22);
            line-height: rem(26);
            margin-bottom: rem(38);
        }
    }

    .title__step-number {
        @extend .Basetica-Medium;
    }

    .project__content {
        padding: 0;
        float: left;
        margin-right: 0;
        width: 100%;

        @include media($large-screen-up) {
            margin-right: rem(25);
            width: rem(650);
        }

        @media (min-width: rem(1115)) {
            width: rem(760);
        }

        @media (min-width: rem(1315)) {
            width: rem(920);
        }

        .steps__content {
            background-color: $mouse-white;
            float: left;
            padding: 0;
            width: 100%;

            @include media($medium-screen-up) {
                padding: rem(18) rem(30) 0 rem(30);
            }
        }

        .asset-image {
            text-align: center;

            img {
                max-width: rem(150);
            }
        }
    }

    .evidence__assets {
        background: $mouse-white;
        padding: rem(13) rem(21);

        @include media($medium-screen-up) {
            padding: rem(16) rem(31);
        }
    }

    .evidence__asset-block {
        border-top: 1px solid $mouse-rule-gray;
        padding-top: rem(16);
        padding-bottom: rem(21);

        // &:first-child {
        //     border: 0;
        //     padding-top: 0;
        // }

        .controls {
            text-align: right;
            font-size: rem(11.85);
            margin-bottom: rem(4);
        }

        .control__edit {
            @extend .Basetica-Medium;
            margin-right: rem(13);
        }

        .control__delete-text {
            margin-right: rem(4);
        }

        .control__edit-text {
            margin-right: rem(3);
        }

        .control__icon {
            position: relative;
            top: rem(2);
        }
    }

    .asset-text {
        font-size: rem(18);
        line-height: rem(24);
        width: 100%;
        max-width: rem(830);
    }

    .evidence-prompt-content {
        max-width: rem(1030);
    }

    .evidence-creators {
        background-color: #ECF9F6;
        padding: rem(20) rem(24);
        position: relative;

        @include media($medium-screen-up) {
            padding: rem(30) rem(30) 0 rem(30);
        }

        hr {
            background: none;
            height: 1px;
            border: 0;
            border-top: 1px solid #AFD6CE;
            padding: 0;
            margin: 0;
        }
    }

    .modal__all-collaborators {
        float: left;
        margin-bottom: rem(20);
    }

    .creators__edit {
        position: absolute;
        top: rem(28);
        right: rem(17);
        margin-left: rem(5);

        @media (min-width: rem(580)) {
            margin-left: rem(10);
            top: 0;
            right: 0;
            position: relative;
        }

        .creators__edit-text {
            display: inline-block;
            font-size: rem(11.85);
            @extend .Basetica-Medium;
            position: relative;
            top: rem(-1)
        }

        .creators__edit-icon {
            display: inline-block;
        }
    }

    .review-icon {
        display: inline-block;
    }

    .review-title {
        display: inline-block;
        float: left;

        h2 {
            display: inline-block;
            margin: 0 rem(15) 0 0;
            position: relative;
            top: rem(-25);
            font-size: rem(22);
            line-height: rem(30);

            @include media($medium-screen-up) {
                font-size: rem(26);
                line-height: rem(30);
            }


        }

        span {
            position: relative;
            top: rem(-25);
            display: block;
            margin-top: rem(40);
            font-size: rem(16);
            line-height: rem(19);

            @include media($medium-screen-up) {
                display: inline-block;
                margin-top: 0;
            }
        }
    }

    .button__submit-work {
        background-color: $mouse-blue;
        clear: both;
        float: left;
        margin-top: rem(0);
        min-width: rem(211);
        font-size: rem(16);

        @include media($large-screen-up) {
            clear: none;
            margin-top: rem(15);
            min-width: rem(216);
            font-size: rem(21);
            float: right;
        }

        &:disabled {
            background-color: $mouse-disabled-gray;
        }
    }

    .evidence-submit {
        background-color: #ECF9F6;
        padding: 0 rem(20) rem(20) rem(20);

        @include media($medium-screen-up) {
            padding: rem(30);
        }
    }

    .project-assets {
        padding: rem(27) rem(31);

        .evidence__asset-block:first-child {
            border: 0;
        }
    }

    .project__plan {
        background-color: $mouse-white;
        width: 100%;

        .step__item-header {
            h2 {
                margin-top: rem(30);
                margin-bottom: rem(18);
            }
        }

        .current-step__content {
            padding-bottom: 0;

            &:first-child {
                h2 {
                    margin-top: 0;
                }
            }
        }

        ul {
            li {
                margin-bottom: rem(12);
            }
        }

        @include media($medium-screen-up) {
            padding: rem(24) rem(28);
        }

        @include media($large-screen-up) {
            max-width: rem(1030);
            padding: rem(25) rem(150) rem(50) rem(29);
        }
    }

    .prompt__response {
        background: $mouse-green;
        padding: rem(20);
        margin: 0 rem(-20) 0 rem(-20);

        @include media($medium-screen-up) {
            margin: 0 rem(-30) 0 rem(-30);
        }
    }

    .highlight__title {
        background-color: $mouse-dark-green;
        border-radius: rem(2);
        color: $mouse-white;
        font-size: rem(22);
        line-height: rem(28);
        padding: rem(8) rem(11);
        margin: 0 0 rem(20) 0;

        @include media($medium-screen-up) {
            font-size: rem(26);
            line-height: rem(28);
        }

        h2 {
            display: block;
            font-size: rem(22);
            line-height: rem(28);
            color: $mouse-white;
            margin: 0;

            @include media($medium-screen-up) {
                display: inline-block;
                font-size: rem(26);
                line-height: rem(28);
            }
        }

        span {
            display: block;
            font-size: rem(16);
            line-height: rem(19);

            @include media($medium-screen-up) {
                display: inline-block;
                margin-left: rem(10);
            }
        }
    }

    .prompt__description {
        @extend .Basetica-Medium;
        font-size: rem(18);
        line-height: rem(22);
        color: $mouse-white;

        ul, ol {
            margin-left: 1.9rem;
            list-style-type: revert;
        }

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(28);

            ul, ol {
                margin-left: 2.9rem;
            }
        }
    }

    .evidence__response {

        .step__icon-state {
            fill: $mouse-green;
        }

        .step__button {
            background: $mouse-white;
            color: $mouse-green;
            margin-right: rem(12);
            width: rem(140);
            margin-bottom: rem(10);

            @media screen and (max-width: rem(500)) {
                width: rem(101);
            }

            &:hover {
                background: $mouse-dark-green;
                color: $mouse-white;

                .step__icon-state {
                    fill: $mouse-white;
                }

            }
        }

        .evidence__buttons {
            @media screen and (max-width: rem(500)) {
                display: flex;
                justify-content: space-between;
            }
        }

    }

    .prompt__hr {
        background: #AFD6CE;
        margin-bottom: rem(20);
        margin-top: rem(17);
    }

    .steps__nav-desktop {

        display: none;
        width: rem(260);

        @include media($large-screen-up) {
            display: block;
        }

        @media (min-width: rem(1153)) {
            width: rem(300);
        }

        float: left;

        .steps {
            li {
                padding-bottom: rem(10);
                padding-top: rem(10);
            }
        }
    }

    .steps__nav-mobile {
        .step__title {
            color: $mouse-black;
        }

        .steps {
            li {
                padding-top: rem(4);
                padding-bottom: rem(4);
            }

            a {
                padding-left: rem(7) !important;
                padding-bottom: rem(12) !important;
                padding-top: rem(12) !important;
            }
        }
    }

    .project__steps-nav {

        .steps {
            font-size: rem(16);
            line-height: rem(23);

            .step {
                @extend .Basetica-Bold;
                border-radius: rem(2);
                display: inline-block;
                font-size: rem(15);
                line-height: rem(23);
                height: rem(24);
                text-align: center;
                padding-top: rem(2);
                min-width: rem(24);
                margin-right: rem(8);
            }

            li {
                border-bottom: 1px solid $mouse-rule-gray;
                font-size: rem(16);
                line-height: rem(23);

                .router-link-active {
                    background-color: $mouse-green !important;
                    color: $mouse-white !important;

                    .prompt__required {
                        color: $mouse-white;
                    }

                    .step__title {
                        color: $mouse-white;
                    }

                    .step {
                        background-color: #CDEAE4;
                        color: $mouse-green;
                    }

                    a {
                        background-color: $mouse-green;
                        border-radius: rem(2);
                        color: $mouse-white;

                    }
                }

                a {
                    display: flex;
                    color: $mouse-cool-gray;
                    padding: rem(7) rem(9);
                    border-radius: rem(2);

                    .step {
                        background-color: $mouse-cool-gray;
                        color: $mouse-white;
                    }

                    &:hover {
                        background-color: $mouse-green !important;
                        color: $mouse-white !important;

                        .step__title {
                            color: $mouse-white;
                        }

                        .prompt__required {
                            color: $mouse-white;
                        }

                        .step {
                            background-color: #CDEAE4;
                            color: $mouse-green;
                        }
                    }
                }
            }
        }

        .prompt__required {
            color: $mouse-green;
            font-style: italic;
            font-size: rem(11);
            line-height: rem(13);
            position: relative;
            top: rem(-4);
        }
    }

    .step-heading {
        margin-bottom: rem(13);

        @include media($medium-screen-up) {
            margin-bottom: rem(20);
        }

        h2 {
            display: block;
            margin-bottom: rem(10);
            margin-right: rem(13);
            font-size: rem(22);
            line-height: rem(30);

            @media (min-width: rem(781)) {
                margin-bottom: 0;
            }

            @include media($medium-screen-up) {
                display: inline-block;
                font-size: rem(26);
                line-height: rem(30);
            }
        }

        span {
            font-size: rem(16);
            line-height: rem(19);
            margin-bottom: rem(10);
            display: block;

            @include media($medium-screen-up) {
                display: inline-block;
                margin-bottom: 0;
            }
        }
    }

    .step__button {
        height: rem(32);
        min-width: rem(100);
        line-height: rem(32);
        padding: 0;
        font-size: rem(16);

        @include media($medium-screen-up) {
            min-width: rem(139);
        }
    }

    .step__button-text {
        margin-right: rem(11);
        position: relative;
        top: rem(1);
    }

    .step__button-icon {
        position: relative;
        top: rem(3);
    }

    .creators__all {
        display: flex;
        flex-wrap: wrap;
    }

    .evidence-creator {
        text-align: center;
        margin-right: rem(20);
        margin-bottom: rem(36);
        width: rem(68);
    }

    .evidence-creator__profile {
        border-radius: 50%;
        overflow: hidden;
        margin: auto;
        height: rem(45);
        width: rem(45);
    }

    .evidence-creator__name {
        color: $mouse-body-gray;
        font-size: rem(11);
        line-height: rem(14);
        margin-top: rem(6);
        word-break: break-all;
        text-align: center;
    }

    .evidence-add__more {
        word-break: keep-all;
    }

    .evidence__creator--add {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $mouse-green;
        border-radius: 50%;
        margin: auto;
        height: rem(45);
        width: rem(45);
    }

    .button__review {
        background-color: $mouse-blue;
        @extend .Basetica-Medium;

        padding: rem(7) 0 0 0;
        height: rem(32);
        font-size: rem(18);
        width: rem(208);

        @include media($medium-screen-up) {
            padding: rem(10) 0 0 0;
            height: rem(40);
            font-size: rem(21);
            width: rem(260);
        }
    }

    .review-text {
        margin-right: rem(12);
    }

    .modal__instructions {
        color: $mouse-body-gray;
        font-size: rem(20);
        line-height: rem(28);
        margin-bottom: rem(15);
    }

    .modal-collaborator {
        display: inline-block;
        float: left;
        padding-top: rem(8);
        position: relative;
        margin: 0 rem(7) rem(6) 0;
        height: rem(130);
        width: rem(102);

        &:after {
            color: $mouse-green;
            content: '+';
            font-size: rem(42);
            position: absolute;
            top: rem(5);
            right: rem(5);
        }
    }

    .modal-collaborator {
        &.active {
            background-color: #CDEAE4;

            &:after {
                content: none;
            }
        }
    }

    .modal-collaborator__pic {
        border-radius: 50%;
        margin: 0 auto rem(5) auto;
        overflow: hidden;
        height: rem(52);
        width: rem(52);
    }

    .modal-collaborator__name {
        color: $mouse-body-gray;
        font-size: rem(11);
        line-height: rem(14);
        padding-left: rem(10);
        padding-right: rem(10);
        word-break: break-all;
        text-align: center;
    }

    .modal-collaborator__pagination {
        clear: both;
        color: $mouse-green;
        font-size: rem(14);
        line-height: rem(22);
        margin-top: rem(21);
        margin-bottom: rem(12);
        @extend .Basetica-Medium;
    }

    .pagination__sign {
        font-size: rem(24);
        position: relative;
        top: rem(3);
    }

    .modal__actions {
        clear: both;
        float: left !important;

        @include media($medium-screen-up) {
            float: right !important;
        }
    }

    .modal__content-text {
        .modal__button {
            margin-top: rem(27);
        }
    }

}

.highlight__title + .prompt__row {
    border-top: none;
    padding-top: 0;
}

.prompt__description {
    p:last-child {
        margin-bottom: 0;
    }
}

.prompt__row {
    display: block;
    border-top: rem(1) solid #AFD6CE;
    padding-top: rem(15);
    margin-bottom: rem(20);

    @include media($medium-screen-up) {
        display: flex;
    }

    .prompt__step {
        margin-right: rem(14);
    }

    .button-step {
        background-color: #CDEAE4;
        color: $mouse-green;
        margin-bottom: rem(20);
        min-width: rem(90);
        height: rem(30);
        padding: rem(5) 0 0;
        line-height: rem(23);
        font-size: rem(16);

        @include media($medium-screen-up) {
            margin-bottom: 0;
        }
    }

    .prompt__description {
        font-size: rem(18);
        line-height: rem(22);
        @extend .Basetica-Medium;
        padding: 0;

        @include media($large-screen-up) {
            font-size: rem(20);
            line-height: rem(28);
            padding-right: rem(165); // For desktop
        }
    }
}

.step__prompt-indicator {
    color: #000000 !important;
    font-size: rem(14) !important;
    margin-top: rem(8);
    text-align: right;
}

.action__cancel {

    padding-top: 0;
    margin: rem(-5) 0 rem(15) 0;
    display: block;
}

.step__item-video {
    .video-js {
        height: auto;
        width: 100%;
    }
}

// Ignore first record
.prompt__title + .prompt__row {
    border-top: none;
    padding-top: 0;
}

.prompt__title {
    h2 {
        color: #ffffff;
        display: block;
        margin-right: rem(17);
        margin-bottom: 0 !important;
        font-size: rem(22);
        line-height: rem(30);

        @include media($medium-screen-up) {
            display: inline-block;
            font-size: rem(26);
            line-height: rem(28);
            margin-bottom: rem(20);
        }
    }

    span {
        font-size: rem(16);
        line-height: rem(19);
        @extend .Basetica-Regular;
    }

    margin-bottom: rem(21);
}

.switch__mobile {
    .switch {
        margin-top: rem(-12);
        margin-bottom: rem(12);
    }
}

html.no-touch {
    .step-pagination {
        &:hover {
            background-color: $mouse-green;

            .pagination__arrow {
                fill: $mouse-white;
            }
        }
    }
}

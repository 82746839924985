/*** DROPDOWN ***/

.dropdown {
  $dropdown-distance-from-menu: 50px;

  font-size: rem(14);

  .dropdown-container {
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: bottom;
  }

  .dropdown-description {
    float: left;
    padding-right: 1em;
  }

  .dropdown-button,
  .dropdown-button-delay {
    cursor: pointer;
    float: right;
    position: relative;
    margin-bottom: 0;
  }

  .dropdown-button::after,
  .dropdown-button-delay::after {
    display: inline-block;
    position: absolute;
    top: auto;
    right: -15px;
    bottom: auto;
    left: auto;
    color: $mediumGray;
    content: "\25BE";
    font-size: rem(18);
  }

  .dropdown-menu {
    @include transition (all 0.2s ease-in-out);
    cursor: pointer;
    // display: none;
    overflow: show;
    position: absolute;
    text-align: left;
    top: $dropdown-distance-from-menu;
    z-index: 100;
    width: rem(270);
    top: rem(38);
    right: rem(-35);
    @include padding(20px);
    background: #252525;
    color: $white;

    li {

      @include padding(12px 0);
      border-bottom: 1px solid #4a4a4a;

      &:first-child {
        padding-top: 0;

        span.menu-label {
          padding-top: 0;
        }
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;

        span.menu-label {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      a {
        color: $white;
        text-decoration: none;
        display: block;

        &:hover {
          color: $red;
        }
      }
    }
  }

  .show-menu {
    display: block;
  }
}


/*** MODAL ***/

.modal {
  $base-border-color: #dcdcdc !default;
  $base-border-radius: 3px !default;
  $base-background-color: #fff !default;
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $action-color: #477dca !default;
  $dark-gray: #333 !default;
  $light-gray: #ddd !default;
  $medium-screen: 40em !default;
  $large-screen: 53.75em !default;
  $base-font-color: $dark-gray !default;
  $modal-padding: 25px;
  $modal-background: $base-background-color;
  $modal-close-color: $green;
  $modal-image-height: 135px;
  $modal-image-width: $modal-image-height;

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  label img {
    display: block;
  }

  .modal-state {
    display: none;
  }

  .modal-fade-screen { // overlay
    @include position(fixed, 0);
    background-color: rgba(#fff, 0.76);
    opacity: 0;
    padding-top: 0.6em;
    text-align: left;
    transition: opacity 0.25s ease;
    visibility: hidden;
    z-index: 99999999999;

    @include media($large-screen) {
      padding-top: 5%;
    }

    .modal-bg {
      @include position(absolute, 0);
      cursor: pointer;
    }
  }

  .modal-close {
    @include position(absolute, ($modal-padding /2) ($modal-padding /2) null null);
    @include size(1.5em);
    background: $modal-background;
    cursor: pointer;

    &::after,
    &::before {
      @include position(absolute, 3px 3px 0 50%);
      @include size(0.15em 1.5em);
      background: $modal-close-color;
      content: "";
      display: block;
      margin: -3px 0 0 -1px;
      transform: rotate(45deg);
    }

    &:hover::after,
    &:hover::before {
      background: darken($modal-close-color, 10%);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  button.modal-close {
    @include position(absolute, ($modal-padding /2) ($modal-padding /2) null null);
    @include size(1.5em);
    background: $modal-background;
    cursor: pointer;
    padding: 0;
    margin-top: 0;
    font-size: rem(16);

    &::after,
    &::before {
      @include position(absolute, 3px 3px 0 50%);
      @include size(0.15em 1.5em);
      background: $modal-close-color;
      content: "";
      display: block;
      margin: -3px 0 0 -1px;
      transform: rotate(45deg);
    }

    &:hover::after,
    &:hover::before {
      background: darken($modal-close-color, 10%);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  .modal-inner {
    background: $modal-background;
    border-radius: $base-border-radius;
    border: 2px solid #E7E7E7;
    margin: auto;
    margin-top: 0;
    overflow: auto;
    padding: $modal-padding / 2;
    position: relative;
    transition: opacity 0.25s ease;
    max-width: rem(860);
    width: 90%;
    box-shadow: 0 2px 4px rgba(173, 173, 173, 50%);

    @include media($medium-screen) {
      padding: $modal-padding;
      // width: 60%;
    }

    @include media($large-screen) {
      // width: 50%;
    }

    .modal-intro {
      margin-bottom: 1em;
    }

    .modal-content {
      color: $base-font-color;

      @include media($medium-screen) {
        columns: 2 8em;
      }
    }

    a.cta {
      color: $base-background-color;
      display: inline-block;
      margin-right: 0.5em;
      margin-top: 1em;

      &:last-child {
        padding: 0 2em;
      }
    }
  }

  .modal-state:checked + .modal-fade-screen {
    opacity: 1;
    visibility: visible;
  }

  .modal-state:checked + .modal-fade-screen .modal-inner {
    top: 0.5em;
  }
}

.modal-open {
  overflow: hidden;
}
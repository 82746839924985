// colors
$white: 								#fafafa;

$brand:                 red;
$brand-primary:         darken($brand, 6.5%);
$brand-secondary:       darken(white, 6.5%);
$brand-highlight: 			darken(white, 6.5%);

$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee




$black: #000000;
$white: #ffffff;
$lightGray: #f4f4f4;
$mediumGray: #8A8A8D;
$darkgray: #4F5452;
$coolGray: #8A8A8D;
$bodyGray: #3A3A3A;
$red: #ff3333;
$green: #00a886;
$mediumgreen: #A2DACF;
$lightgreen: #EFF9F7;
$greenHover: #028E6D;
$cardLightGreen: #E6F6F2;
$cardActionGreen: #ECF9F6;

$text: $bodyGray;

// paths
$base-image-path: '/assets/img/';

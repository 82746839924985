// Tables
//
// The basis for tables that appear on the site. Note, that responsive tables are dependent on  populating the ```data-label``` attribute on each ```<td>```. See code example below.
//
// Markup: tables.html
//
// Styleguide Components.Tables

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	font-size: rem(14);
	margin-bottom: 3rem;
	border-bottom: 1px solid $green;

	a, i {
		color: $green;
		@extend .Basetica-Medium;
		text-decoration: none;
	}

	caption {
		text-align: left;
		font-size: rem(17);
		color: #333333;
		@extend .Basetica-Regular;
		padding: 0 .5rem .5rem 0;
		font-weight: normal !important;

		&.large-table-caption {
			font-size: rem(24);
			line-height: rem(28);
			margin-bottom: rem(10);
			@extend .Basetica-Regular;
			padding: 0;
		}
	}

	tbody tr:nth-child(even) {
		background: #E7E7E7;
	}

	thead {
		background: $green;
		@extend .Basetica-Medium;
		color: $white;
		border-radius: 2px;
	}

	thead tr {
		border-radius: 2px;

		td:first-child {
			border-radius: 2px 0 0 2px;
		}
		td:first-child {
			border-radius: 2px 0 0 2px;
		}

		.fa	{
			color: $white;
		}
	}

	th,td {
		padding: rem(9) rem(15) !important;
		text-align: left;
	}

	th {
		text-transform: capitalize;
		@extend .Basetica-Medium;
		font-weight: normal !important;
		span.sort {

            cursor: pointer;
            position: relative;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 10px solid #fff;
                position: absolute;
                margin-left: auto;
                top: 2px;
                right: -17px;
                opacity: 0.5;
			}
		}

        span.sort__active {
            &:after {
                opacity: 1;
            }
        }

        span.sort__descend {
            &:after {
                border-bottom: 10px solid #fff;
                border-top: 0;
            }
        }

        &:last-of-type {
            padding-right: 30px !important;
        }

	}
}

h1.table-title {
	border-bottom: none;
}

h2.table-label {
	font-size: rem(17);
	@extend .Basetica-Medium;
	margin-bottom: rem(5);
}

@include media($medium-screen) {
	table thead, table tbody, table tfoot {
		border: none;
	}
}

@media only screen and (max-width: 785px)  {

table {
	/*
	Label the data
	*/
	th:before,
	td:before {
		content: attr(data-label);
        color: $mouse-black;
	}

	td[data-label="In Badges"] {
		min-height: rem(36);
	}

	/* Force table to not be like tables anymore */
	thead, tbody, th, td, tr {
		display: block;
	}

	thead {
		border: none;
	}

	tbody {
		border: none;
		border-top: 1px solid $green;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead th  {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	thead tr {
		display: none;
	}

	tr {
        padding-bottom: rem(8);
		padding-top: rem(8);
		border-bottom: 1px solid $green;

		&:last-child {
			border-bottom: none;
		}
	}

	td,
	th {
		/* Behave  like a "row" */
		border: none;
		position: relative;
		padding-left: 50% !important;
		padding-top: rem(8) !important;
		padding-bottom: rem(8) !important;

		div {
			display: inline;
		}
	}

	th:before,
	td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		left: 15px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		font-family: Basetica-Medium;
	}



	}
}

.table__cell__valign-top {
	vertical-align: top;
}

.table__cell__valign-top-small {
	vertical-align: top;
	@include media($medium-screen-up) {
		vertical-align: middle;
	}
}

.table__cell__valign-middle {
	vertical-align: middle;
}

.table__cell__valign-bottom {
	vertical-align: bottom;
}

.table__text__block {
	display: block;
}

.table__actions {
	color: $green;
	a {
		display: inline-block;
	}
}

.table__actions--divider {
	padding-left: rem(8);
	padding-right: rem(8);
}

.no__actions {
    color: $mouse-body-gray;
}


.profile-view {
	@include span-columns(12);
	@include media($large-screen-up) {
		@include span-columns(9);
		@include shift(2);
	}

	p.bio,
	ul {
		margin-bottom: rem(20);
	}

	.badge-block {
		margin-bottom: rem(10);
	}
}

.profile-header {
	@include row();
	margin-bottom: rem(20);
	padding-bottom: rem(20);

	h1.page-title {
		font-size: rem(30);
		line-height: rem(37);
		border-bottom: none;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	.profile-pic {
		@include media($medium-screen-up) {
			float: left;
			margin-right: rem(20);
		}

		img {
			border-radius: 50%;
            height: 97px;
			width: 97px;
		}
	}

	.username {
		@include media($medium-screen-up) {
			float: left;
			margin-right: rem(12);
			padding-top: rem(30);
		}
	}

	.profile-edit {
		font-size: rem(14);
		@extend .Basetica-Medium;
		@include media($medium-screen-up) {
			float: left;
			padding-top: rem(42);
		}
	}

	.bio {
		clear: both;

		p {
			float: left;
			margin-top: rem(20);
			margin-bottom: 0;
		}
	}
}

.profile-group-list {
	margin-bottom: rem(27);
	list-style-type: disc;
	margin-left: 15px;
	margin-bottom: rem(29);
}

.primary-content .profile-pic {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: block;
    border-radius: 50%;
	img {
		min-width: 100%;
        min-height: 100%;
	}
}

%round-image {
    width: auto;
    height: auto;
    max-width: 28px;
    max-height: 28px;
    border-radius: 50%;
    @include media($medium-screen-up) {
        margin-bottom: 3px;
    }
    @include media($large-screen-up) {
        margin-bottom: 0;
    }
}

.profile__pic-small {
	@extend %round-image;
}

.profile__pic-small img {
    @extend %round-image;
}

.profile__pic-member img {
    @extend %round-image;
}

.profile__pic-small-left {
	float: left;
}
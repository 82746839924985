body {
	font-family: Basetica-Regular;
	font-size: rem(16);
	line-height: rem(21);

	@include media($medium-screen-up) {
		line-height: rem(22);
	}
}

/***** GRID *****/

.container,
.page-wrap {
	@include outer-container();
}

.grid-row {
	@include row();
}

.large-8-cols {
	@include span-columns(12 of 12);
	@include media($medium-screen-up) {
		@include span-columns(8 of 12);
	}
}

.large-6-cols {
	@include span-columns(12 of 12);
	@include media($medium-screen-up) {
		@include span-columns(6 of 12);
	}
}

.large-4-cols {
	@include span-columns(12 of 12);
	@include media($medium-screen-up) {
		@include span-columns(4 of 12);
	}
}

.large-3-cols {
	@include span-columns(12 of 12);
	@include media($medium-screen-up) {
		@include span-columns(3 of 12);
	}
}

.large-12-cols {
	@include span-columns(12 of 12);
}

/***** LOGO *****/

.logo {
	@include span-columns(3);
	font-size: rem(30);

	.mouse {

	}

	.create {
		color: $red;
	}
}

/***** TYPOGRAPHY *****/

h1,h2,h3,h4,h5,h6 {
	color: $black;
}

p {
	margin-bottom: rem(27);
	line-height: rem(21);

	@include media($medium-screen-up) {
		line-height: rem(22);
	}

	&.instructional {
		line-height: rem(20);
	}
}

a {
	color: $green;
	text-decoration: none;
}

h1.page-title {
	font-size: rem(30);
	border-bottom: 1px solid #D0D0D1;
	margin-bottom: rem(26);
	line-height: rem(36);
	padding-top: rem(12);
	@include media($medium-screen-up) {
		padding-top: 0;
	}
}

h1.group-title {
	font-size: rem(30);
	line-height: rem(34);
	padding-bottom: rem(10);
	margin-bottom: rem(10);
	margin-top: rem(30);
	border-bottom: 0;
}

.section-heading {
	font-size: rem(24);
	line-height: rem(28);
	margin-bottom: rem(10);
}

.sub-section {
	@extend .Basetica-Medium;
	font-size: rem(17);
	line-height: rem(21);
}

hr {
	height: 1px;
	border: none;
	background: #D0D0D1;
	clear: both;
	margin-bottom: rem(27);
}

/***** ALIGNMENT *****/

.align-right {
	text-align: right;
}

.float-right {
	float: right;
}

.no-float {
	float: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.list-number-style {
    list-style: decimal;
    margin-left: 20px;

    li {
        line-height: 24px;
    }
}

.content__has-lists {

    li {
        font-size: rem(16);
        line-height: rem(21);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(28);
        }
    }

    ol {
        list-style-type: decimal;
        margin-left: rem(32);
        margin-top: rem(10);
        margin-bottom: rem(30);

        @include media($medium-screen-up) {
            margin-left: rem(63);
        }

        li {
            margin-bottom: rem(12);
        }
    }

    ul {
        list-style-type: disc;
        margin-left: rem(32);
        margin-top: rem(10);
        margin-bottom: rem(30);

        @include media($medium-screen-up) {
            margin-left: rem(63);
        }

        li {
            margin-bottom: rem(12);

            p {
                margin-bottom: rem(36);
            }
        }
    }
}

.text-center {
	text-align: center;
}

.sm-block {
	display: block;
	@include media($medium-screen-up) {
		display: inline;
	}
}

.sm-hidden {
	display: none;
	@include media($medium-screen-up) {
		display: inline;
	}
}
// Filter - Form Based
//
// Currently supports dropdown via select elements
//
// Markup: filter_form.html
//
// Styleguide Components.Filter.form

.filter--form {
    @include media($medium-screen-up) {
        height: rem(40);
    }

    margin-bottom: rem(12);
}

.filter--form form {
	@include row();
	@include media($medium-screen) {
		float: left;

	}
}

.filter--form--label {
	font-size: rem(17);
	font-family: $Basetica-Medium;
	margin-right: rem(23);
	margin-bottom: rem(17);
	@include media($medium-screen) {
		float: left;
		margin-bottom: 0;
		@include span-columns(1.5);
		padding-top: rem(8);
	}
	@include media($large-screen) {
		padding-top: rem(8);
		@include span-columns(1.2);
	}
}

.filter--form--content {

    form {
        // @include media($medium-screen) {
            // width: rem(650);
            // @include span-columns(7);
        // }

        // @include media($large-screen) {
        //     @include span-columns(6);
        // }
    }

    @include media($medium-screen) {
        @include span-columns(10.5);
    }

    @include media($large-screen) {
        @include span-columns(10.8);
	}
}

.filter--form--criteria {
	margin-bottom: rem(10);
	@include media($medium-screen) {
		// width: auto;
		margin-bottom: 0;
		margin-right: rem(12);
        // @include span-columns(4);
        width: rem(200) !important;

        &:last-child {
            margin-right: 0;
        }
    }

    @include media($large-screen) {
        // min-width: rem(200);
    }
}

.filter--form--medium {
    @include media($medium-screen) {
        width: rem(500) !important;
    }
}


.filter--clear--filter {
    display: inline-block;
    font-family: Basetica-Medium, sans-serif;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    margin-top: rem(10);
    margin-bottom: rem(10);
    @include span-columns(4);

    @include media($medium-screen) {
        margin-top: rem(10);
        margin-left: rem(5);
        @include span-columns(2);
    }

    @include media(920px) {
        margin-left: rem(20);
        margin-top: rem(10);
        @include span-columns(1.5);
    }
}

.admin__wrap .filter--form--content button {
    margin-bottom: rem(8) !important;
}
body {
	background: $lightGray;
}

main {
	overflow: auto;
}

.main--overflow-visible {
    overflow: visible;
}

.page-wrap {
	@include outer-container();

	@include media($large-screen-up) {
		padding-top: rem(50);
	}

	&.work {
		@include media($large-screen-up) {
			padding-top: rem(30);
		}
	}

	.primary-content {
       overflow-x: hidden;
		@include span-columns(11);
		@include shift(1);

		@include media($large-screen-up) {
			@include span-columns(9);
			@include shift(0);
		}

		.content-box {
			background: $white;
			box-shadow: rgba($black,.5) 0 2px 4px;
			padding: rem(20);

			@include media($medium-screen-up) {
				padding: rem(35) rem(60);
			}

			ul {
				list-style-type: disc;
				margin-left: rem(20);

				li ul {
					margin-top: rem(10);
					margin-bottom: rem(10);
					margin-left: rem(35);
				}
			}
		}
	}

	.sidebar {

		display: none;

		@include media($large-screen-up) {
			display: block;
			@include span-columns(3);
		}

		&.left {
			@include shift(1);
		}

		h3.step-title {
			font-size: rem(24);
			padding-bottom: rem(22);
			border-bottom: 1px solid #D0D0D1;
		}
	}

	body.single-column & {
		@include media($large-screen-up) {
			@include outer-container(916px);
		}

		.primary-content {
			@include shift(0);
			@include span-columns(12);

			@include media($large-screen-up) {
				@include span-columns(14);
			}
		}
	}

	body.single-column-12 & {
		.primary-content {

			@include media($large-screen-up) {
				@include shift(1);
				@include span-columns(13);
			}

			.wrap-12 {
				@include span-columns(12 of 13);
				padding-bottom: rem(20);
				p {
					max-width: rem(800);
				}
			}
		}
	}

	body.project-cards & {
		.primary-content {
			@include media($large-screen-up) {
				@include span-columns(10);
			}
		}
	}

	body.sidebar-left & {

	}

	body.sidebar-right & {
			.primary-content {
				@include span-columns(11);
				@include shift(.5);
				@include media($large-screen-up) {
					@include shift(1);
					@include span-columns(9);
			}
		}
	}
}
// Notifications
//
// The basis for notifications that appear on the site
//
// Markup: notifications.html
//
// Styleguide Components.Notifications

.notification-form{
	h2{
		font-size: rem(24);
		margin: 10px 0 10px;
	}

    .form-row {
        margin-bottom: rem(20);
    }
}
li.menu-notifications {
  position: relative;
  a {
    svg path {
      fill: #636366;
    }
    &:hover {
      svg path {
        fill: #00a886;
      }
    }
  }
  &.active {
    a svg path {
      fill: #00a886;
    }
  }
  .notification-count {
    position: absolute;
    top: -5px;
    right: 10px;
    padding: .3em .5em;
    line-height: 1em;
    border-radius: 1em;
    background-color: #FF3333;
  }

  @media (max-width: 767px) {
    .notification-count {
        right: 54px;
    }
  }

  @media (max-width: 710px) {
    .notification-count {
        right: 45px;
    }
  }

  @media (max-width: 660px) {
    .notification-count {
        right: 40px;
    }
  }

  @media (max-width: 560px) {
    .notification-count {
        right: 35px;
    }
  }

  @media (max-width: 530px) {
    .notification-count {
        right: 35px;
    }
  }

  @media (max-width: 500px) {
    .notification-count {
        right: 30px;
    }
  }

  @media (max-width: 460px) {
    .notification-count {
        right: 20px;
    }
  }
}

.notifications {
  padding: .5rem 1rem 1rem 1rem;
}
.notifications__view-more {
  text-align: center;
  padding: 2em;
  color: #00A886;
  cursor: pointer;
}
.notifications__header {
  display: flex;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    margin-top: 2em;
  }
  .notifications__title,
  .notifications__read-toggle {
    width: 33%;

  }
  @media (max-width: 740px) {
    display: block;
    .notifications__title,
    .notifications__read-toggle {
      width: 100%;
    }
  }
}
.notifications__read-toggle {
  text-align: center;
  color: #00A886;
  cursor: pointer;
  font-size: .9em;
  @media (max-width: 740px) {
    margin-top: 1em;
  }
}
.notifications__title {
  font-size: 1.5em;
  margin-bottom: 0;
  margin-left: .5em;
}
.notifications__background {
  position: absolute;
  left: -74px;
  bottom: 145px;
  opacity: .17;
  @media (max-width: $medium-screen) {
    display: none;
  }
}
.notification {
  position: relative;

  color: #393939;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0,0,0,.3);
  border-radius: 2px;
  margin-bottom: .4em;
  cursor:pointer;
  .notification__container {
    display:flex;
    box-sizing: border-box;
    flex-direction: row;
  }
  a {
    color: #393939;
  }
  .notification__avatar {
    position: relative;
    background: #E7E7E7;
    padding: .5em;

    -webkit-border-top-left-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    .notification__avatar-wrap {
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      max-width: 35px;
      max-height: 35px;
      margin-top: 4px;
    }
    img, svg {
      width: 35px;
      min-width: 35px;
      min-height: 35px;
      height: 35px;
    }
    &:after {
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid #E7E7E7;
      content: " ";
      position: absolute;
      left: 100%;
      top: 18px;
    }
  }
  .notification__status {
    position: absolute;
    top: .5em;
    right: .5em;
    img, svg {
      max-width: 17px;
      max-height: 24px;
    }
  }

  .notification__body {
    display: flex;
    flex-grow: 1;
    padding: 1em 1.5em 1em 1.25em;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background: #fff;
    line-height: 1.5em;
    -webkit-border-top-right-radius: 2px;
    -webkit-border-bottom-right-radius: 2px;
    -moz-border-radius-topright: 2px;
    -moz-border-radius-bottomright: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  &.notification--educator {
    .notification__avatar {
      background: #00A886;
      &:after {
        border-left-color: #00A886;
      }
    }
  }
  &.notification--highlight {
    .notification__avatar {
      background: #FF3333;
      &:after {
        border-left-color: #FF3333;
      }
    }
  }
  &.notification--read {
    .notification__container {
      opacity: .4;
    }
  }
  &:hover .notification__container {
    opacity: 1;
  }
  .notification__user {
    //font-family: Basetica-Bold, sans-serif;
  }
  .notification__message {

  }
  .notification__time {
    padding-left: 1em;
    color: #8A8A8D;
    font-size: .8em;
  }
  &.notification--no-link.notification--read {
    cursor: default !important;
  }
  &:not(.notification--no-link) {
    .notification__message {
      text-decoration: underline;
    }
  }
  &:hover {
    .notification__body {
      background: #00906b;
      .notification__user, .notification__message {
        color: #fff !important;
      }
    }
    &.notification--educator .notification__body {
      background: #00906b;
    }
    &.notification--highlight .notification__body {
      background: #ee302e;
    }
    .notification__time {
      color: #9bd6d0 !important;
    }
  }
}
#app-wrap > header {
  position: relative;
  z-index: 100;
}
.group-bar {
  position: relative;
  z-index: 15;
}

.preview__actions {
    .button-edit {
        margin-right: rem(30);
    }
}
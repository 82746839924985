h1.brand {
    margin-top: 100px;
}

#icon-shuffle  {
    font-size: 36px;
}

.form-text-center{
	text-align: center !important;
}
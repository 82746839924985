.course__page {
    @include outer-container();
    padding-top: 0;
}

.course__top-header {
    background-color: $mouse-white;
    left: rem(20);
    margin: rem(-20) rem(-20) 0 rem(-20);
    padding: rem(20) rem(20) rem(7) rem(20);
    top: 0;
    z-index: 200;

    @include media($medium-screen-up) {
        background-color: #FAFAFA;
    }
}

.course__row {
    padding-bottom: rem(35);
    padding-top: rem(35);

    .row__title {
        font-size: rem(26);
        line-height: rem(42);
        margin-bottom: rem(16);
    }

    &:nth-of-type(1) {
        padding-top: 0;
    }
}

.course__bottom-border {
    border-bottom: 1px solid $mouse-rule-gray;
}

.course__bottom-row {
    border-bottom: 0;
    border-top: 1px solid $mouse-rule-gray;
    background-color: #ECF9F6;
    margin-bottom: 0;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;

    @include media($large-screen-up) {
        margin-bottom: -50px;
    }
}

.course__heading {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.course__title {
    display: flex;
    align-items: center;
    margin-left: 0;
    font-size: rem(24);
    line-height: rem(28);

    @include media($medium-screen-up) {
        margin-left: rem(21);
        font-size: rem(36);
        line-height: rem(42);
    }
}

.course__description {
    font-size: rem(16);
    line-height: rem(21);
    margin-bottom: rem(15);
    overflow: hidden;
    height: rem(150);
    width: auto;

    @media screen and (min-width: 975px) {
        width: rem(850);
    }

    @include media($medium-screen-up) {
        font-size: rem(20);
        line-height: rem(30);
        float: none;
    }

    p {
        font-size: rem(16);
        line-height: rem(21);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(30);
        }
    }

    strong {
        @extend .Basetica-Bold;
        font-weight: normal;
    }

    em {
        font-style: italic;
    }

    ul {
        list-style: disc;
        margin-bottom: rem(27);
        margin-left: rem(35);

        @include media($medium-screen-up) {
            font-size: rem(20) !important;
            line-height: rem(28) !important;
        }
    }

    ol {
        list-style: decimal;
        margin-bottom: rem(27);
        margin-left: rem(35);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(28);
        }
    }
}

.full-height {
    height: auto;
}

.course__icon {
    display: flex;
    align-items: center;
    height: rem(60);
    max-width: rem(60);
    margin-right: rem(10);
}

.course__add-to-course {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    text-align: left;

    button {
        margin: 0;
        font-size: rem(18) !important;
        line-height: rem(18) !important;

        svg {
            margin-left: rem(4);
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

    @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-top: rem(20);
    }

    @include media($medium-screen-up) {
        text-align: right;
    }
}

.course__more-button {
    display: flex;
    font-family: Basetica-Medium, sans-serif;
    font-size: rem(18);
    line-height: rem(22);

    svg {
        margin-left: rem(10);
    }

    span {
        display: flex;
        align-items: center;
    }
}

.course__project-cards {
    .card {
        max-width: rem(293);

        h2 {
            a {
                line-height: rem(32);
            }
        }
    }

    justify-content: center;

    @include media($medium-screen-up) {
        justify-content: flex-start;
    }
}

.course__top-header--fixed {
    box-shadow: 0 2px 4px 0 $mouse-footer-gray;
    position: fixed;
    margin-top: 0 !important;
    top: 0;
    width: 100%;

    .course__heading {
        margin-bottom: rem(15.4);
    }

    .course__icon {
        height: rem(42);
        width: rem(42);
    }

    .course__title {
        font-size: rem(26);
    }
}

.badge__items {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.badge__item-wrapper {
    position: relative;
    margin: 0;
    margin-right: rem(21);
    margin-bottom: rem(27);
    width: rem(165);

    @media screen and (max-width: 435px) {
        width: 45%;
    }

    &:nth-child(even) {
        @media only screen and (max-width: 455px) {
            margin-right: 0;
        }
    }
}

.badge__item {
    background-color: $mouse-white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    height: rem(200);
    padding: rem(12);
}

.badge__learn-more {
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge__learn-link {
    display: flex;
    align-items: center;
    margin-top: rem(11);
    font-family: Basetica-Medium, sans-serif;
    font-size: rem(15);
    line-height: rem(22);

    svg {
        margin-left: rem(5);
    }
}

.related_course-description {
    float: none;
    width: auto;
    font-size: rem(20);
    line-height: rem(30);

    p {
        font-size: rem(16);
        line-height: rem(21);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(30);
        }
    }

    @include media($medium-screen-up) {
        @include span-columns(8 of 12);
    }
}

.badge__modal {
    box-shadow: 0 2px 4px 0 $mouse-footer-gray;
    left: 0;
    top: 0;
    padding: rem(30);
    position: absolute;
    background-color: $mouse-white;
    width: 92vw;
    z-index: 100;

    @media (min-width: 900px) {
        width: rem(860);
    }

    .badge__exit {
        position: absolute;
        top: rem(16);
        right: rem(12);
    }

    .badge__modal-body {
        display: block;

        @include media($medium-screen-up) {
            display: flex;
        }
    }

    .badge__modal-content {
        flex: 1;

        @include media($medium-screen-up) {
            padding-right: rem(50);
        }

        h2 {
            @extend .Basetica-Bold;
            font-size: rem(22);
            line-height: rem(25);
            margin-bottom: rem(17);
        }

        .sub-heading {
            font-size: rem(22);
            line-height: rem(25);
            @extend .Basetica-Regular;
        }

        span {
            font-size: rem(16);
            line-height: rem(25);
        }

        p {
            font-size: rem(16);
            line-height: rem(25);
        }

        b {
            @extend .Basetica-Bold;
            font-size: rem(16);
            line-height: rem(25);
        }

        ul {
            list-style: disc;
            margin-left: rem(20);
            font-size: rem(16) !important;
            line-height: rem(26) !important;
        }
    }

    .badge__modal-image {
        height: rem(140);
        margin-bottom: rem(25);
        width: rem(140);
        margin-right: rem(36);

        @include media($medium-screen-up) {
            margin-bottom: 0;
        }
    }

}

.course__project-sort {
    background-color: $mouse-green;
    border-radius: rem(2);
    color: $mouse-white;
    display: inline-block;
    font-size: rem(20);
    height: rem(28);
    @extend .Basetica-Bold;
    text-align: center;
    margin-right: rem(8);
    width: rem(30);
}

.course__wrapper {
    @include outer-container();
}
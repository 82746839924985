
/*
---
hide
---

.visually-hidden,
.hide {
  @extend %visuallyhidden;
}

*/

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/*

---
Break Points
---


*/

@mixin for-large-screen {
  @media only screen and (max-width: 60em) {
    @content;
  }
}

@mixin for-medium-screen {
  @media only screen and (max-width: 40em) {
    @content;
  }
}

@mixin for-small-screen {
  @media only screen and (max-width: 30em) {
    @content;
  }
}

/*
---
Retina ready background images
  prep: image.svg, image.png, image@2x.png
---

body {
  @include background-image('pattern');
}

*/

$image-path: $base-image-path !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false){
    background-image: url(#{$image-path}/#{$name}.svg);
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
        }
    }
}

/*
---
Animations
---

@include keyframes(slide-down) {
  0% { opacity: 1; }
  90% { opacity: 0; }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  @include animation('slide-down 5s 3');
}

*/

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

/*
---
Transitions
---
a {
  color: gray;
  @include transition(color .3s ease);
  &:hover {
    color: black;
  }
}

*/

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

/*
---
Draw Triangle
---
*/
/*
* @include triangle within a pseudo element and add positioning properties (ie. top, left)
* $direction: up, down, left, right
*/
@mixin triangle($direction, $size: 6px, $color: #222){
  content: '';
  display: block;
  position: absolute;
  height: 0; width: 0;
  @if ($direction == 'up'){
    border-bottom: $size solid $color;
    border-left: 1/2*$size solid transparent;
    border-right: 1/2*$size solid transparent;
  }
  @else if ($direction == 'down'){
    border-top: $size solid $color;
    border-left: 1/2*$size solid transparent;
    border-right: 1/2*$size solid transparent;
  }
  @else if ($direction == 'left'){
    border-top: 1/2*$size solid transparent;
    border-bottom: 1/2*$size solid transparent;
    border-right: $size solid $color;
  }
  @else if ($direction == 'right'){
    border-top: 1/2*$size solid transparent;
    border-bottom: 1/2*$size solid transparent;
    border-left: $size solid $color;
  }
}

@mixin natural-animation($property: all, $time: 250ms) {
  -webkit-transition: $property $time cubic-bezier(0.35, 0, 0.25, 1);
  transition: $property $time cubic-bezier(0.35, 0, 0.25, 1);
}

.pagination {

    list-style: disc;

    li {
        display: inline-block;
        font-size: rem(14);
        line-height: rem(20);

        a {
            display: block;
            color: #0a0a0a;
            padding: rem(3) rem(10);

            &:hover {
                background: #e6e6e6;
            }
        }
    }

    .disabled {
        padding: rem(10);
        color: #cacaca;
        cursor: not-allowed;
    }
}

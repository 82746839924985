// Forms
//
// The basis for forms that appear on the site
//
// Markup: forms.html
//
// Styleguide Components.Forms

/**** BASE STYLES ****/

textarea,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="time"],
input[type="week"],
select {
	width: 100%;
	border: 1px solid #D0D0D1;
	height: 40px;
	border-radius: 2px;
	background: #fff;
	@include padding(0 12px);
	font-size: rem(16);
	-webkit-appearance: none;
  	-webkit-border-radius: 0px;
	-moz-appearance: none;
  	-moz-border-radius: 0px;
  	border-radius: 0;
}

input[type="checkbox"],
input[type="radio"] {
	margin-right: rem(10);

	+label {
		display: inline-block !important;
		@extend .Basetica-Regular;
	}
}

input[type="file"] {
	margin-bottom: 1rem;
}

textarea {
	min-height: rem(107);
	@include padding(12px);
}

textarea.prefilled {
	height: rem(107);
	@include padding(12px);
}

select {
	background: #ffffff url('/img/select-bg.png') right no-repeat;
	background-size: 40px 40px;
	padding-right: rem(45);
}

select[multiple] {
	width: 100%;
	border: 1px solid #D0D0D1;
	border-radius: 2px;
	background: #fff;
	@include padding(12px);
	font-size: rem(16);
  	-webkit-border-radius: 0px;
  	-moz-border-radius: 0px;
  	border-radius: 0;
  	background: none;
  	margin-bottom: 1rem;
  	height: 100%;
}

.form-row {
	@include row();
	margin-bottom: rem(20);

	@include media($medium-screen-up) {
		margin-bottom: rem(40);
	}

	&.short-row {
		margin-bottom: rem(10);
	}

	.form-1-4 {
		@include span-columns(12 of 12);
		margin-bottom: rem(20);
		@include media($medium-screen-up) {
			@include span-columns(3 of 12);
			margin-bottom: 0;
		}
	}

	.form-1-3 {
		@include span-columns(12 of 12);
		margin-bottom: rem(20);
		@include media($medium-screen-up) {
			@include span-columns(4 of 12);
			margin-bottom: 0;
		}
	}

	.form-1-2 {
		@include span-columns(12 of 12);
		margin-bottom: rem(20);
		@include media($medium-screen-up) {
			margin-bottom: 0;
			@include span-columns(6 of 12);
		}
	}

	&.condensed {
		margin-bottom: 0;
	}
}

label {
	display: block;
	margin-bottom: rem(10);
	font-size: rem(17);
	@extend .Basetica-Medium;

	&:last-child {
		margin-bottom: 0;
	}

	span {
		font-size: rem(12);
		@extend .Basetica-Italic;
		float: right;
		color: $green;
	}

	&.options {
		@extend .Basetica-Regular;
	}
}

legend, .legend {
	display: block;
	margin-bottom: rem(10);
	font-size: rem(17);
	@extend .Basetica-Medium;

	span {
		font-size: rem(12);
		@extend .Basetica-Italic;
		float: right;
		color: $green;
	}
}

.cancel {
	margin-left: rem(10);
	font-size: rem(15);
	display: inline-block;
	padding-top: rem(10);
	vertical-align: top;
	color: $mediumGray;
	a {
		color: $red;
	}

}

p.field-instructions {
	margin-top: rem(5);
	margin-bottom: 0;
}

.associated-field {
	margin-top: rem(10);
}


::-webkit-input-placeholder {
	color: #3a3a3a;
}

:-moz-placeholder { /* Firefox 18- */
	color: #3a3a3a;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: #3a3a3a;
}

:-ms-input-placeholder {
	color: #3a3a3a;
}

.form-success {
	font-size: rem(13);
	@extend .Basetica-Italic;
	color: $green;
	margin-top: 5px;
}

.form-error {
	@extend .form-success;
	color: $red;
}

.input-error {
    border-color: $red;
}



/**** REGISTRATION ****/

form.form-registration {

	.form-block {
		border-bottom: 1px solid #D0D0D1;
		@include margin(0 0 35px 0);

		h3 {
			font-size: rem(17);
			@extend .Basetica-Medium;
			@include margin(0 0 20px 0);
		}
	}

	label {
		display: block;
		margin-bottom: rem(10);
		font-size: rem(17);
		@extend .Basetica-Medium;

		&:last-child {
			margin-bottom: 0;
		}

		span {
			font-size: rem(12);
			@extend .Basetica-Italic;
			float: right;
			color: $green;
		}

		&.options {
			@extend .Basetica-Regular;
		}
	}

	.register {
		text-align: right;
	}

	.login a {
		text-decoration: none;
		color: $green;
		font-size: rem(18);
		display: inline-block;
		padding-top: rem(10);

		span {
			color: $red;
			font-size: rem(15);
		}
	}

	.captcha div {
		margin: 0 auto;
	}

}

/**** RESET PASSWORD FORM ****/

.reset-password-form {
	h3 {
		margin-bottom: rem(10);
		font-size: rem(17);
		@extend .Basetica-Medium;
	}
}


/**** SUBMIT BUTTONS ****/
.submit-button-centered {
	text-align: center;
	button,
	.button {
		width: 100%;
		padding-top: rem(11);
		padding-bottom: rem(11);
		font-size: rem(18);

		@include media($small-screen-up) {
			width: rem(300);
		}
	}
}


/**** LOGIN FORM ****/

.log-in-form,
.tight-form {
	label {
		display: block;
		margin-bottom: rem(10);
	}

	.login-button {
		text-align: center;
		button,
		.button {
			width: 100%;
			padding-top: rem(15);
			padding-bottom: rem(15);

			@include media($small-screen-up) {
				width: rem(300);
			}

			@include media($medium-screen-up) {
				padding-top: rem(11);
				padding-bottom: rem(11);
			}
		}
	}
}

.log-in-form p {
	margin-bottom: 8px;
}

.login-welcome {
	text-align: center;
	.instructional {
		margin-bottom: 10px;
	}
}

.login-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	span.spacing {
		padding: 0 3px;
		@include media($medium-screen-up) {
			padding: 0 10px;
		}
	}
}

.login-grid {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	.login-grid-login {
		width: 100%;
		border-bottom: 1px solid #D0D0D1;
		margin-bottom: 32px;
		@include media($medium-screen-up) {
			width: 50%;
			padding-right: 50px;
			border-right: 1px solid #D0D0D1;
			border-bottom: none;
		}
		.form-row {
			margin-bottom: 22px;
		}
		button {
			margin-top: 5px;
			border-radius: 2px;
			min-height: 41px;
		}
	}
}

.login-grid-google {
	width: 100%;
	@include media($medium-screen-up) {
		width: 50%;
		padding-left: 50px;
	}
	button {
		padding: 0;
		display: inline-flex;
		align-items: center;
		width: 100%;
		line-height: normal;
		border-radius: 2px;
		min-height: 41px;
		.text {
			padding: 0 1rem;
			flex: 1;
		}
		.icon {
			background-color: #E6F6F2;
			padding: .35rem 1rem;
			display: flex;
			height: 100%;
			border: 1px solid $green;
			border-radius: 0 2px 2px 0;
		}
	}
}

.login-grid-google.profile {
	width: auto;
	padding-left: 0;
	button {
		width: auto;
		.text {
			padding: 0 2rem;
		}
	}
}

select::-ms-expand { display: none; }

.form-button {
	text-align: center;
	button,
	.button {
		width: 100%;
		padding-top: rem(15);
		padding-bottom: rem(15);

		@include media($small-screen-up) {
			width: rem(300);
		}

		@include media($medium-screen-up) {
			padding-top: rem(11);
			padding-bottom: rem(11);
		}
	}
}

.simple-join-group {
	input[type="text"] {
		margin-top: rem(10);
	}
}

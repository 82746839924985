.comments {
  .project-comments-header {
    position: relative;
    padding: 2rem 1.5rem .5rem 1.5rem;
  }
  .comments__content {
    padding: 1rem 0;
    min-height: 200px;
  }
  .comments__threads {
    padding-bottom: 1rem;
  }
  .comments__view-more {
    border-top: 2px solid $gray-lighter;
    display: flex;
    padding: 1rem .5rem;
    color: $gray-light;
    flex-direction: row;
    justify-content: space-between;
  }
  .comments__view-previous {
    border-bottom: 2px solid $gray-lighter;
    display: flex;
    margin-top: -1rem;
    padding: 0rem .5rem .5rem .5rem;
    margin-bottom: 1rem;
    color: $gray-light;
    flex-direction: row;
    cursor: pointer;
    justify-content: space-between;
  }
  .comments__no-comments-status {
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-align: center;
    color: $green;
    @extend .Basetica-Light;
    i {
      font-size: 4rem;
    }
  }
}

.comments-form {
  .comments-form__prompt {
    color: rgba(255,255,255,.7);
    position: absolute;
    top: 9px;
    font-size: .8rem;
  }
  textarea.comment-form__input {
    position: relative;
    height: auto;
    min-height: 40px;
    color: $green;
    outline: none;
    box-shadow: none;
    border-radius: 3px;
    resize: none;
    z-index: 10;
  }
  .comment-form__actions {
    position: relative;
    float: left;
    @include clearfix();
  }
  .comment-form__submit {
    float: right;
    position: relative;
    background: #ECF9F6;
    border-radius: 3px;
    color: $green;
    padding: .4em .3em .3em .4em;
    font-size: 1em;
    margin: 0;
    margin-top: 5px;
    box-shadow: none;
    font-family: Basetica-Medium, sans-serif;
    &.comment-form__submit--disabled {
      opacity: .5;
      cursor: default;
    }
    @include clearfix();
  }
  .comment-form__action {
    list-style: none;
    float: left;
    border-radius: 50%;
    margin-right: .5rem;
    width: 41px;
    height: 40px;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
    color: rgba(255,255,255,.6);
    z-index: 5;
    &:hover {
      svg path:not(#Oval-5) {
        fill: #fff;
      }
    }
    svg {
      position: relative;
      top: 50%;
      transform: translate(0,-50%)
    }
  }
}
.comment-thread {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 1em .5em .5em .5em;
  margin-bottom: .5em;
  align-items: center;

  &.comment-thread--is-deleting {
    .comment-thread__body {
      filter: blur(3px);
      opacity: .5;
    }
  }
  .comment-thread__user-avatar {
    position: relative;
    border-radius: 50%;
    width: 3em;
    align-self: flex-start;
    height: 3em;
    margin-right: 1em;
    border: 1px solid $gray-lighter;
    > a, > div:not(.comment-thread__type) {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .comment-thread__creator-label {
    background: #E7E7E7;
    padding: .15em .35em 0em .35em;
    color: #5F5F5F;
    font-size: .9em;
    display: inline-block;
    margin-right: .5em;
  }
  .comment-thread__type {
    position: absolute;
    top: -.4em;
    right: -.4em;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    svg {
      transform: scale(0.6) translate(-40%, -40%)
    }
  }
  .comment-thread__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: rem(15px);
  }
  .comment-thread__content {
    padding-bottom: .25em;
  }
  .comment-thread__user-link {
    color: $green;
    @extend .Basetica-Bold;
  }
  .comment-thread__text {
    padding-right: .5em;
  }
  .comment-thread__time {
    display: inline-block;
    color: $gray-light;
    font-size: .8em;
  }
  .separator {
    display: inline-block;
    padding: 0 .25em;
    font-size: .7em;
  }
  .comment-thread__saved-notice {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    color: $green;
    border-radius: 3px;
    padding: .25em;
    background: #ffffff;
    border: 1px solid #ECF9F6;
    line-height: 1em;
  }
  .comment-thread__actions {
    font-size: rem(13px);
    color: $green;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .comment-thread__manage {
    color: $green;
    display: flex;
    flex-direction: row;
  }
  .comment-thread__manage-action {
    cursor: pointer;
    font-size: rem(13px);
    color: $green;
    margin-right: .25em;
  }
  .comment-thread__replies {
    padding: 1em 0;
    font-size: rem(13px);
    .comment-thread__body {
      font-size: rem(13px)
    }
    .comments-form textarea.comment-form__input {
      min-height: 20px;
      font-size: rem(13px);
      line-height: rem(18px);
      padding: .47em .47em .30em .47em;
    }
    .comment-form__submit {
      background: #ECF9F6;
      color: #00a886;
    }
  }
  .comment-thread__delete-confirmation {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    a {
      display: inline-block;
      border: 1px solid $green;
      background: $white;
      border-radius: 3px;
      padding: .15em 1em;
      margin-top: .25em;
      margin-right: 1em;
      cursor: pointer;
    }
    a.confirm {
      background: $green;
      color: $white;
    }
    z-index: 20;
  }
  .comment-thread__reply-form {
    display:flex;
    flex-direction: row;
    padding: .5em 1em .5em .5em;
    .comments-form {
      position: relative;
      flex: 1;
    }
  }
  &.comment-thread--highlight {
    background: #ffebe4;
    border-left: 4px solid #ff2f31;
  }
}
.project-comments-header {
  position: relative;
  overflow: hidden;
  .project-comments-header__bg-character {
    position: absolute;
    top: 0;
    right: 10px;
  }
}

.comment__count {
    position: relative;
    top: -7px;
}

.evidence-reorder {
    .asset {
        position: relative;
        margin-bottom: 20px;
        border: 1px solid rgba(10, 10, 10, .25);
    }
}

.evidence {
    .asset {
        position: relative;
        margin-bottom: rem(40);
        padding: rem(20);
        border: 1px solid #D0D0D1;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        h2,h3 {
            @extend .sub-section;
        }
    }
}
.evidence-review {
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 1rem;
  }
}
.evidence-asset {
  padding: rem(20) 0 rem(25);
  text-align: left;
  border-bottom: 2px solid $gray-lighter;

  .evidence-download{
    .button {
      margin-top: rem(10);
      margin-bottom: 0;
      font-size: rem(15);
      @include media($medium-screen-up) {
        font-size: rem(18);
      }

      .fa {
        -ms-transform: rotate(-45deg); /* IE 9 */
        -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
        transform: rotate(-45deg);
      }
    }
    text-align: center;
  }
  &.evidence-asset--text {
    text-align: left;
  }
  &.evidence-asset--image {
    text-align: center;
    img {
      max-width: 100%;
      padding-left: rem(20);
      padding-right: rem(20);
    }
  }
  &.evidence-asset--file {
    text-align: center;
    .evidence-asset--file---box {
      text-align: left;
      display: inline-block;
      background: $green;
      color: $white;
      padding: rem(15);
      border-radius: 2px;
      margin-bottom: rem(10);
      @include media($medium-screen-up) {
        min-width: rem(299);
      }


      .file-icon {
        float: left;
      }

      .file-info {
        float: left;
        padding-top: rem(5);
        .file-name {
          font-size: rem(16);
        }
        .file-size {
          font-size: rem(14);
        }
      }

      .fa {
        font-size: rem(56);
        color: #13C3A0;
        margin-right: rem(15);
        float: left;
      }
    }
  }
}
.evidence-review__file {
   @include span-columns(12);
   float: none;
}
.project-evidence-header {
    background: $mouse-green url(/img/evidence-modal-robot.svg) bottom right no-repeat !important;
    position: relative;
    overflow:hidden;
    font-size: 1.4rem;
    background-position-x: calc(100% - 32px);
}
.project-evidence-header__character {
  position: absolute;
  right: rem(17);
  bottom: 0;
}
.project-header__title {
  line-height: 1.25em;
  @extend .Basetica-Medium;
  @media (max-width: $small-screen) {
    font-size: rem(20px) !important;
  }
}
.project-evidence-header__group,
.project-evidence-header__users {
  position: relative;
  padding: .5em 0;
  @extend .Basetica-Medium;
}
.evidence-review__review-actions {
  background: #dcf2ea;
  text-align: center;
  margin: 1rem -1.5rem -1.5rem -1.5rem;
  padding: 2rem;
  .button {
      font-size: rem(15);
      @include media($medium-screen-up) {
        font-size: rem(18);
      }
  }
}
.evidence-review__export {
  text-align: center;
  margin-top: rem(25);

  .button {
      font-size: rem(15);
      @include media($medium-screen-up) {
        font-size: rem(18);
      }

      &:hover {
        background-color: $greenHover;
      }
  }
}
.project-evidence-header__users {
  padding-bottom: rem(20);
}
.project-evidence-header__users-list {
  position: relative;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 1rem 0 1rem;
  margin-left: -20px;
  margin-right: -20px;
  height: 87px;
  overflow: hidden;
  &.expanded {
    height: auto;
  }
  @include natural-animation(height);
}
.project-evidence-user {
  position: relative;
  text-align: center;
  font-size: .75em;
  width: 80px;
  line-height: 1em;
  margin-bottom: .5em;
  .project-evidence-user__avatar img {
    width: 2.75em;
    height: 2.75em;
    border-radius: 50%;
  }
  .project-evidence-user__name {
    font-size: .7em;
    line-height: 1em;
    word-wrap: break-word;
    word-break: break-all;
    span {
	    color: $white;
        padding: 0 .5em;
        display: inline-block;
    }
  }
}
.project-evidence-creator-display-toggle {
  font-size: .6em;
  cursor: pointer;
  margin-top: 1.5rem;
  i {
    padding-left: .5em;
  }
}

.work-area {
    padding-bottom: 1rem;
}

.project-evidence__prompts {

    .highlight__title {
        background-color: $mouse-dark-green;
        border-radius: rem(2);
        color: $mouse-white;
        font-size: rem(22);
        line-height: rem(28);
        padding: rem(8) rem(11);
        margin: 0 0 rem(20) 0;

        @include media($medium-screen-up) {
            font-size: rem(26);
            line-height: rem(28);
        }

        h2 {
            display: block;
            font-size: rem(22);
            line-height: rem(28);
            margin: 0;
            color: $mouse-white;
            border-bottom: 0 !important;
            padding-bottom: 0 !important;

            @include media($medium-screen-up) {
                display: inline-block;
                font-size: rem(26);
                line-height: rem(28);
            }
        }

        span {
            font-size: rem(15);
            line-height: rem(20);

            @include media($medium-screen-up) {
                margin-left: rem(10);
                font-size: rem(16);
                line-height: rem(19);
            }
        }
    }


    // h2 {
    //     border-bottom: 0 !important;
    //     padding-bottom: 0 !important;
    //     display: inline-block;
    // }

    // span {
    //     font-size: rem(16);
    //     line-height: rem(19);
    // }

    .prompt__description {
      padding-right: 0;

      ul, ol {
        margin-left: 1.9rem;
        margin-bottom: 1.6875rem;
        list-style-type: revert;
      }

      @include media($medium-screen-up) {
        ul, ol {
          margin-left: 2.9rem;
        }
      }
    }
}
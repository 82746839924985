// My Work Cards
//
// Markup: card-mywork.html
//
// Styleguide Components.Cards.mywork

.work{

	h1{
		font-size: rem(30);
		line-height: rem(24);
		@include margin(0 0 8px 0);
		@include padding(15px 15px 10px 0);
		border-bottom: solid 1px #D0D0D1;
	}
	h3{
		@include margin(0 0 5px 0);
	}

	.mywork-category{
		line-height: rem(28);
		font-size: rem(24);
		@include margin(0 0 8px 0);
		@include padding(15px 15px 10px 0);
		color: $black;
		overflow: auto;
	}

	.list {

        clear: both;
        margin-top: rem(12);

    	li:hover .card_overlay {
    			display: block;
    			opacity: 0.85;
    		}

		.card {
			position: relative;
			height: 305px;
            min-width: 293px;

            @media (min-width: 500px) {
                @include span-columns(5 of 11);
            }

            @include media($large-screen-up) {
                @include span-columns(3 of 10);
            }

			.card__content{
                position: relative;
				height: 245px;
				width: 100%;
			}

            .card__actions {
                background: #ECF9F6;
                color: $green;
                padding: 8px;
                height: 60px;
                font-size: rem(13);
            }

            .card__creator-image {
                display: inline-block;
                float: left;
                img {
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    height: 45px;
                    width: 45px;
                    float: left;
                }
            }

            .card__collaborator-count {
                display: inline-block;
                float: left;
                margin-left: 3px;
                margin-top: 13px;
            }

            .card__reactions {
                display: inline-block;
                margin-top: 8px;
                margin-left: rem(20);
                svg {
                	height: 24px;
                }
            }

            .card__like {
                float: left;
                margin-right: 3px;
            }

            .car__question {
                margin-right: 3px;
            }

            .car__question, .card__idea {
                float: left;
                margin-left: 1px;
                margin-top: 1px;
            }

            .card__reaction-count {
                float: left;
                margin-left: 5px;
                margin-top: 6px;
            }

            .card__views {
                display: inline-block;
                float: right;
                margin-top: 11px;
                svg {
                	height: 23px;
                }
            }

            .card__view-count {
                margin-left: 3px;
                margin-top: 2px;
            }

            .car__views-icon {
                float: left;
            }

            .card__view-count {
                float: left;
            }

			.card_overlay{
 				display: none;
				background-color: $lightgreen;
				height: 245px;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				div{
					position: relative;
					display: inline-block;
					background-color: $lightgreen;
					top: 25%;
					margin: -50% 5px 0 0;
					width: 100%;
				}
				.row{
					margin: 0;
					padding: 0;
					line-height: rem(50);
					color: $green;
					text-align: center;
					font-size: rem(14);
				}
			}

			h2 {
				font-size: rem(22);
				line-height: rem(28);
				@include margin(0 0 8px 0);
				@include padding(15px 15px 0 15px);

				a {
					color: $black;
					text-decoration: none;
				}
			}

			div.options{
				text-align: center;
				button{
					font-size: 14px;
					@include padding(8px);
				}

			}

			div.promo {
				height: 245px;
				width: 100%;
				float: left;
    			overflow: hidden;
				background-color: $cardLightGreen;
				background-size: cover;
				background-position: 50% 50%;
				img {
					min-width: 100%;
					min-height: 100%;
					object-fit: cover;
				}
			}

			a.text{
				@include margin(18px 18px 9px 18px);
				display: block;
				height: 218px;
				position: relative;
				font-size: 14px;
				color: $darkgray;
			}

			a.file{
				@include padding(30px 0 0 0);
				height: 245px;
				// background-color: #CDEAE4;
				color: $lightgreen !important;
				display: block;
				text-align: center;

				.fa{
					display: block;
					color: #13C3A0 !important;
				}
				div{
					display: block;
				    color: $green !important;
					span{
						@include padding(0 0 0 10px);
					}
				}

			}

			a.image {
				width: 100%;
				height: 245px;
				float: left;
				overflow: hidden;
// 				margin-bottom: rem(15);
				position: relative;

				@include media($medium-screen-up) {
					height: 172px;
				}

				img {
					width: 100%;
					position: absolute;
					top: -9999px;
				    bottom: -9999px;
				    left: -9999px;
				    right: -9999px;
				    margin: auto;

					@media (min-width: 500px) {
						height: 100%;
						min-width: 100%;
						max-width: 130%;
						width: auto;
					}

					@include media($medium-screen-up) {
						height: 100%;
						min-width: 100%;
						max-width: 130%;
						width: auto;
					}
				}
			}



			.row{
				margin: 0;
				padding: 0;
				line-height: rem(50);
				color: $green;
                font-size: rem(14);
            }

            .title {
                bottom: 0;
                background-color: #00A886;
                color: #ffffff;
                opacity: 0.85;
                padding: 8px 15px;
                position: absolute;
                width: 100%;
            }

			.status {
				text-align: center;
				background-color: $lightgreen;
				font-size: rem(28);
			}

			.ellipsis {
				text-overflow: ellipsis;

				/* Required for text-overflow to do anything */
				white-space: nowrap;
				overflow: hidden;
			}
		}

	}

	.tag .active{
		background: $green;
	}

	.actions{
		color: $green;
		font-size: rem(15);
        margin-right: rem(15);
	}

    .dropdown-menu {
        display: block;
    }

    .main-menu {
        position: relative;
		margin-top: -20px;
		margin-left: -20px;
		margin-right: -20px;
        z-index: 50;
    }

}

.work-modal{

	.work-modal-header {
	  position: relative;
	  overflow:hidden;
	  font-size: 1.4rem;
      z-index: 1000;
	}
	.work-modal-character {
	  position: absolute;
	  right: 17px;
	  top: 13px;
      z-index: -50;
	}
	.work-modal-subheader {
        .label {
            font-size: rem(24px);
            line-height: rem(30px);

            // TODO: shouldnt have to override this setting
            padding-left: 0;
            padding-right: 0;

            @media (max-width: $small-screen) {
                font-size: rem(20px) !important;
                line-height: rem(25px);
            }
        }
	  padding-bottom: rem(10);
	}
	.work-modal-catlist{
		font-size: 14px;
		&:first-of-type {
			margin-top: rem(5);
		}
	}
	.work-modal-category-input{

		input{
			color: $green;
		}

	}


	a.work-modal-remove-category{
		padding: 0;
		margin: 0;
		color: $white;
	}

	.work-modal-actions {
        margin-top: 15px;
        text-align: right;
	}
	.work-modal-users {
	  padding-bottom: 0;
	}
	.work-modal-users-list {
	  position: relative;
	  display:flex;
	  flex-direction: row;
	  flex-wrap: wrap;
	  padding: 1rem 0 0 0;
	  margin-left: -20px;
	  margin-right: -20px;
	  height: 80px;
	  overflow: hidden;
	  &.expanded {
	    height: auto;
	  }
	  @include natural-animation(height);
	}
	select{
        min-width: 330px;
		width: 60%;
		color: #8A8A8D !important;
	}
}
.fa-rotate-45 {
    transform: rotate(45deg);
}

.mywork-category--name {
    float: left;
    margin-right: rem(15);
    display: inline-block;
}

.mywork-category--actions {
	line-height: 1.2;
    float: left;

    @include media($small-screen-up) {
        @include span-columns(12);
    }

    @include media($medium-screen-up) {
        @include span-columns(6);
    }
}

.btn {
    height: 30px;
    font-size: rem(15);
    padding: 0;
    width: 176px;
}

.work-modal-actions {
    .button__modal {
        &:hover {
            float: right;
            position: relative;
            background: #ECF9F6;
            border-radius: 3px;
            color: #00a886;
            padding: .4em .3em .3em .4em;
            font-size: 1.125rem;
            margin: 0;
            margin-top: 5px;
            outline: none;
            box-shadow: none;
            font-family: Basetica-Medium, sans-serif;
        }
    }
}

.mywork-cateogry__button--disabled {
    opacity: .5;
    cursor: default;
}

.mywork--load-more {
    clear: both;
    text-align: center;

    @include span-columns(10);

    a.button {
        margin-right: 8%;
    }

    a.button:hover {
        background-color: $greenHover;
    }
}

.evidence-review__export-arrow {
    float: right;
    margin-left: 6px;
}
#badge {
	.badge_details{
	    img {
	        padding: 0 10px 10px 0;
	        margin: 0;
	    }
	}
}

.project-block {
	border-bottom: 1px solid #D0D0D1;
	margin-bottom: 2rem;
}

.badge-block {
	float: left;

	.badge {
		@include span-columns(4 of 12);
		@include omega(3n);
		@include media($small-screen-up) {
			@include omega-reset(3n);
			@include span-columns(2 of 12);
			@include omega(6n);
		}
	}

}
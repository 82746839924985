.list {
    .evidence-card-wrapper {

        @media (min-width: 560px) {
            @include span-columns(5 of 11); // uncomment to revert
            width: 250px;
        }

        @include media($large-screen-up) {
            @include span-columns(3 of 10);
            width: 250px;
        }
    }
}

.slick-slide {
    .evidence-card {
        margin: 0px auto;
    }
}

.evidence-card {
    position: relative;
    height: 262px !important;
    width: 250px;
    margin-bottom: rem(32);
    background: $white;
    box-shadow: 0 2px 4px rgba($black,.5);

    @media (max-width: 559px) {
        margin-left: auto;
        margin-right: auto;
    }
}

.evidence-card__content {
    position: relative;
    height: 209px;
    width: 100%;
}

.evidence-card__overlay {
    background-color: $lightgreen;
    height: 209px;
    width: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
}

.evidence-card__content {
    &:hover {
        .evidence-card__overlay {
            display: block;
            opacity: 0.85;
        }
    }
}

.evidence-card__title {
    bottom: 0;
    background-color: $green;
    color: $white;
    opacity: 0.85;
    padding: 8px 15px;
    position: absolute;
    width: 100%;
}

/* Card Action Area */
.evidence-card__actions {
    background: $white;
    color: $green;
    padding: 8px;
    height: 52px;
}

.evidence-card__action-group {
    @include span-columns(3 of 9);
}

.evidence-card__creator-image {
    display: inline-block;
    float: left;

    img {
        border: 1px solid #ccc;
        border-radius: 50%;
        height: 38px;
        width: 38px;
        float: left;
    }
}

.evidence-card__collaborator-count {
    display: inline-block;
    margin-left: 3px;
    margin-top: 10px;
    position: relative;
}

.evidence-card__reactions {
    margin-top: 7px;
    text-align: center;
}

.evidence-card__reaction-count {
    display: inline-block;
    position: relative;
    top: -5px;
}

.evidence-card__like {
    float: left;
    margin-right: 3px;
}

.evidence-card__question {
    display: inline-block;
    position: relative;
}

.evidence-card__idea {}

.evidence-card__views {
    display: inline-block;
    float: right;
    margin-top: 7px;
}

.evidence-card__views-icon {
    float: left;
}

.evidence-card__views-count {
    float: left;
    margin-left: 3px;
    margin-top: 2px;
}

.evidence-card__asset {
    height: 209px;
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    background-color: $cardLightGreen;
    background-size: cover;
    background-position: 50% 50%;

    img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
}

.evidence-card__text {
    @include padding(18px 18px 9px 18px);
    font-size: 14px;
    color: $darkgray;
}

.evidence-card__file {
    @include padding(30px 0 0 0);
    height: 209px;
    display: block;
    text-align: center;

    div {
        display: block;
        color: $green !important;
        span {
            @include padding(0 0 0 10px);
        }
    }
}

.evidence-card__file-info {
    margin: 0;
    padding: 0;
    line-height: rem(50);
    color: $green;
    font-size: rem(14);
}

.evidence-card__blank {}

.no-access__card {
    border-bottom: none;
    height: auto;
    float: left;
    min-height: rem(533);
    margin: 0;
    padding: rem(21);
    width: 100%;
    max-width: rem(463);

    @include media($medium-screen-up) {
        width: 50%;
    }

    @include media($large-screen-up) {
        width: rem(463);
    }
}

.no-access__overlay {
    background-color: $mouse-green;
    display: flex;
    align-items: center;
    height: rem(50);
    position: absolute;
    left: 0;
    top: rem(40);
    width: rem(235);

    svg {
        margin-left: rem(12);
    }

    .overlay__text {
        color: $mouse-white;
        font-size: rem(18);
        line-height: rem(23);
        @extend .Basetica-Bold;
        margin-left: rem(14);
    }
}

.no-access__message {
    margin-top: rem(30);
    margin-left: rem(20);
    float: left;

    svg {
        margin-bottom: rem(30);
    }


    @include media($medium-screen-up) {
        width: 50%;
    }

    @media screen and (min-width: 1101px) {
        width: rem(499);
        margin-left: rem(69);
    }
}

.no-access__image {
    margin-bottom: rem(20);
}

.no-access__content {
    h1 {
        font-size: rem(30);
        line-height: rem(37);
        margin-bottom: rem(20);
    }

    p {
        color: #000000;
        font-size: rem(20);
        line-height: rem(28);
    }
}

.card__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .card {
        float: none;
    }
}
@import '../framework/bourbon/assets/stylesheets/bourbon';
@import '../framework/neat/assets/stylesheets/neat-helpers';

$visual-grid: false;
$column: 105px;
$gutter: 25px;
$grid-columns: 12;
$max-width: 1440px;

// Breakpoint
$small-screen: 600px;
$medium-screen: 768px;
$large-screen: 1024px;
$x-large-screen: 1440px;

$small-screen-up: new-breakpoint(min-width $small-screen 12);
$medium-screen-up: new-breakpoint(min-width $medium-screen 12);
$large-screen-up: new-breakpoint(min-width $large-screen 14);
$x-large-screen-up: new-breakpoint(min-width $x-large-screen 14);

$fg-column: $column;
$fg-gutter: $gutter;

// ------------------------------------------------------------
// Omega Reset
// ------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}
.admin__wrap {
    @include outer-container(90%);
    padding-top: rem(50);

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        margin-bottom: rem(15);
    }

    .table__pagination__list {
        color: #ffffff;
        margin: 0px 6px;
        width: auto !important;
    }
}

.admin-card {
    position: relative;
    background: $white;
    box-shadow: 0 2px 4px rgba($black,.5);
    border-bottom: 12px solid $green;
    margin-bottom: rem(32);

    @include span-columns(10 of 11);

    @media (min-width: 500px) {
        @include span-columns(5 of 11);
    }

    @include media($medium-screen-up) {
        @include span-columns(3 of 10);
    }

    h2 {
        font-size: rem(22);
        line-height: rem(28);
        @include margin(0 0 8px 0);
        @include padding(15px 15px 0 15px);

        a {
            color: $black;
            text-decoration: none;
        }
    }
}

.admin__wrap {
    h2 {
        font-size: rem(22);
        margin-bottom: rem(15);
    }
}

.admin__wrap {
    form {
        select {
            margin-bottom: rem(20);
        }

        input[type="text"] {
            margin-bottom: rem(20);
        }

        input[type="date"] {
            margin-bottom: rem(20);
        }

        textarea {
            margin-bottom: rem(20);
        }
    }
}

.admin-content-box{
    @include padding(6px);
    margin: 10px 0 20px 0;
    @include clearfix;
}

.admin__row {
    @include row();
    margin-bottom: rem(20);

    .course-description {
        background: $mouse-white;
        margin-bottom: 30px;

        p, ol, ul {
            margin-bottom: rem(25);
        }

        em {
            font-style: italic;
        }
    }
}

.admin__column {

    @include span-columns(12);

    @include media($medium-screen-up) {
        @include span-columns(6);
    }
}

.admin__full-column {
    @include span-columns(12);
}

.admin-table {

    margin: 0 0 10px 0;

    .center{
        text-align: center;
    }

    .featured{
        color: $green;
    }

    input {
        font-size: rem(14);
        line-height: rem(16);
        height: rem(28);
        @include padding(4px);
        @include margin(4px);
        border-bottom: 1px solid #D0D0D1;
    }

    button {
        font-size: rem(14);
        line-height: rem(16);
        height: rem(28);
        @include padding(4px 10px 4px 10px);
        @include margin(4px);
    }

    a.clear_btn{
        background: $lightGray;
        line-height: rem(16);
        height: rem(28);
        color: $green;
        border: none;
        border-radius: 2px;
        @include padding(4px 10px 4px 10px);
        @include margin(4px);
        margin-top: 40px !important;
    }

}

.admin-nav {
    text-align: center;
    ul{
        list-style-type: none;
    }
    li{
        float: left;
    }
    li a {
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
    }
    li a:hover{
        text-decoration: underline;
    }
}

.admin__badges {
    .admin__badge {

        @include span-columns(3 of 12);
        margin: 0;
        padding-left: rem(15);
        padding-right: rem(15);
        margin-bottom: rem(35);

        img {
            border: solid 4px #fefefe;
            box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
            display: inline-block;
            line-height: 0;
            max-width: 100%;
            transition: box-shadow 200ms ease-out;
            border-radius: 0;
            margin-bottom: rem(10);
        }
    }

    .clear__badge {
        @include clearfix;
    }
}

.admin__badge-description {
    @include span-columns(8 of 12);
}
.admin__badge-icon {
    @include span-columns(3 of 12);
}

.admin__card-lower-info {
    clear: both;
}

.admin__card {

    float: left;
    display: block;
    position: relative;
    margin-bottom: 2rem;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-bottom: 12px solid #00a886;
    padding: rem(10);

    @include media($medium-screen-up) {
        @include span-columns(5 of 12);
    }

    .admin__card-info {
        @include span-columns(6 of 12);
    }

    .admin__card-action {
        @include span-columns(6 of 12);
    }
}

.cancel__require-button {
    button {
        margin-right: rem(4);
    }
}

.cancel__require-button {
    margin-right: rem(3);
}

.site__list {
    margin-bottom: rem(20);
}

.course__image {
    margin-bottom: rem(20);
}

.admin-dropdown {
    display: none;
}

.delete-form {
    display: inline;
}

.btn-delete-admin {
    padding: 0;
    font-weight: 100;
    font-size: 16px;
    color: salmon;
    background: none;
    display: inline;
    margin-bottom: 0;

    &:hover {
        background: none !important;
        color: salmon;
    }
}

.delete-form {
    display: inline-block;

    button {
        background: none !important;
        color: salmon !important;
        padding: 0px !important;
        padding-top: 14px !important;

        &:hover {
            background: none !important;
        }
    }
}

.admin-icon-row .btn-delete-admin {
    padding-top: 0 !important;
}

#educator-code {
    display: none;
}

// Bourbon/Refills Modals
//
// These are currently used everywhere except My Work
//
// Markup: modal-refills.html
//
// Styleguide Components.Modals.refills


.modal {

	.asset-block {
		margin-bottom: rem(27);
	}

	textarea {
		width: 100%;
		min-height: rem(180);
	}

	label {
		margin-top: 1rem;
	}

	.fa {
		color: $green;
	}

	.error {
		display: none;
	}

	.error-active {
		display: block;
	}

	button,
	.button {
		float: right;
		margin-top: 1rem;
		&.float-none {
			margin-top: 0;
			float: none;
		}
	}

	.modal-callout {
		margin: 1rem 0;
		padding: 1rem;
		border: 1px solid #D0D0D1;
	}

	h2 {
		font-size: rem(22);
		margin-bottom: rem(10);
		@extend .Basetica-Medium;
	}
}

.callout {
	button,
	button {
		background: none;
	}
}

.modal__global--active {
    position: fixed;
}

.modal__global {

    .modal-inner {
        border-radius: 0;
        border: none;
        overflow: visible;
        padding: 0;
        height: auto;
        margin-bottom: 50px;
    }

    .modal__close {
        background: none;
        position: absolute;
        top: 12.5px;
        right: 12.5px;
        height: 1.5em;
        width: 1.5em;
        cursor: pointer;
        padding: 0;
        margin-top: 0;
        font-size: 1rem;
    }

    .modal__callout {
        border-radius: rem(2);
        border: 2px solid $mouse-rule-gray;
        color: $mouse-cool-gray;
        margin-bottom: rem(18);
        padding: rem(10) rem(13);
    }

    button.modal-close {
        background: none;

        &::before, &::after {
            background: $mouse-white;
        }
    }

    .modal-fade-screen {
        background-color: rgba(255, 255, 255, 0.9);
        overflow: scroll;
    }

    .modal__header {
        background: $mouse-green;
        display: flex;
        align-items: center;
        height: rem(71);
        padding-left: rem(22);

        h2 {
            @extend .Basetica-Regular;
            margin: 0;
            color: $mouse-white;
            font-size: 26px;
            line-height: 22px;
        }

    }

    .modal__content {

        padding: rem(32) rem(35) rem(50) rem(25);

        label {
            margin-top: 0;
        }

        .modal__file-input {
            input[type="file"] {
                font-size: rem(16);
                font-style: italic;
            }
        }

        .modal__disclaimer {
            display: flex;
        }

        .modal__icon {
            margin-left: rem(3);
        }

        .modal__disclaimer-copy {
            margin-left: rem(16);

            p {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .modal__sure-copy {
            font-size: rem(20);
            line-height: rem(28);
        }

        .modal__publish-gallery {
            margin-top: rem(38);
            margin-bottom: 0;

            @include media($medium-screen-up) {
                margin-bottom: rem(19);
            }
        }

        h3 {
            @extend .Basetica-Medium;
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: rem(12) !important;
        }

        hr {
            margin-top: rem(25);
            margin-bottom: rem(29);
        }

        .modal__actions {
            text-align: right;
            width: 100%;

            .action__cancel {

                text-align: left;

                a {
                    color: $mouse-red;
                    margin-right: rem(26);
                    @extend .Basetica-Medium;
                }

                @media screen and (min-width: rem(500)) {
                    text-align: right;
                    display: inline-block;
                    padding-top: rem(10);
                    margin: 0 0 0 rem(10);
                }
            }

            .action__proceed {
                a {
                    background-color: $mouse-blue;
                    border-radius: rem(2);
                    color: $mouse-white;
                    display: inline-block;
                    @extend .Basetica-Medium;
                    font-size: 21px;
                    line-height: 15px;
                    padding: 14px 10px 11px 10px;
                    text-align: center;
                    width: 100%;

                    @media screen and (min-width: rem(500)) {
                        width: rem(300);
                    }
                }
            }
        }

    }
}

.project-tab {
    .modal {
        textarea {
            min-height: rem(112);
        }

        .modal__button {
            margin: 0;
            height: rem(40);
            width: rem(170);

            &:disabled {
                background-color: $mouse-disabled-gray;
                color: $mouse-white;
            }
        }
    }
}
// Page Header w/ Actions
//
// These page headers appear for educators in the group tabs
//
// Markup: header-actions.html
//
// Styleguide Components.PageHeader.actions

.header__admin {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #D0D0D1;
	margin-bottom: rem(25);
	padding-bottom: rem(25);
}

.header__admin--no-rule {
    border-bottom: none;
    padding-bottom: 0;
}

.header__admin__text {
	padding-right: rem(32);
	margin-bottom: 0;
}

.header__admin__text__title {
	font-size: rem(28);
	line-height: rem(28);
	font-family: $Basetica-Regular;
	margin-bottom: rem(12);
	@include media($medium-screen-up) {
		font-size: rem(30);
		line-height: rem(28);
	}
}

.title--no-margin {
    margin-bottom: 0;
}

.header__admin__text__subtitle {
	font-size: rem(14);
	line-height: rem(18);
	color: #4A4A4A;
	font-family: $Basetica-Regular;
}

.header__admin__actions {
	padding-top: rem(5);
	color: $green;
	font-size: rem(13);
	font-family: $Basetica-Medium;
}

.header__admin__actions--edit {
	margin-right: rem(10);
	display: inline-block;
	margin-bottom: rem(5);
	&:after {
		content: "\f040";
	    font-family: FontAwesome;
	    font-style: normal;
	    font-weight: normal;
	    padding-left: 4px;
	    cursor: pointer;
	    font-size: rem(16);
	}

    @include media($medium-screen-up) {
        margin-right: rem(20);
    }
}

.admin__actions--edit {
    a {
        &:after {
            content: "\f040";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            padding-left: 4px;
            cursor: pointer;
            font-size: rem(16);
        }
    }
}

.playlist__actions--edit {
    @extend .header__admin__actions--edit;
    margin-bottom: 0;
    color: $green;
    font-size: rem(13);
    font-family: $Basetica-Medium;
}

.header__admin__actions--delete {
	margin-right: rem(10);
	display: inline-block;
	margin-bottom: rem(5);
	&:after {
		content: "\f014";
	    font-family: FontAwesome;
	    font-style: normal;
	    font-weight: normal;
	    padding-left: 4px;
	    cursor: pointer;
	    font-size: rem(16);
	}

    @include media($medium-screen-up) {
        margin-right: rem(20);
    }
}

.header__admin__actions--notification {
	margin-right: rem(10);
	display: inline-block;
	margin-bottom: rem(5);
	&:after {
		content: "\f0e0";
	    font-family: FontAwesome;
	    font-style: normal;
	    font-weight: normal;
	    padding-left: 4px;
	    cursor: pointer;
	    font-size: rem(16);
	}

    @include media($medium-screen-up) {
        margin-right: rem(20);
    }
}

.header__intro {
    color: $mouse-black;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(25);
}

.header__intro__title {
	font-size: rem(24);
	line-height: rem(28);
	padding-right: rem(17);
}

.header__intro__summary {
	font-size: rem(14);
	line-height: rem(20);
	padding-top: rem(7);
	@include media($medium-screen-up) {
		font-size: rem(16);
		line-height: rem(19);
	}
}

.header__intro__secondary {
    margin-top: rem(-20);
    font-size: rem(14);
    width: 100%;
}

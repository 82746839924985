// Gallery Slider
//
// Currently used in Project Gallery area
//
// Markup: gallery.html
//
// Styleguide Components.Gallery

.gallery {
    @include outer-container();
    padding-top: 0;

    .main-menu {
        margin-top: -20px;
        margin-left: -20px;
        margin-right: -20px;
    }

    .dropdown-menu {
        display: block;
    }

    hr:last-child {
        display: none;
    }
}

.gallery--top-spacing {
    padding-top: 44px;
}

.gallery__titles {
    margin-bottom: 14px;
    @include media(500px) {
        @include span-columns(12 of 12);
    }
}

.gallery__main-title {
    font-size: rem(30);
    line-height: rem(28);
    margin-bottom: 4px;
}

.gallery__sub-title {
    @include span-columns(8 of 10);
    font-size: rem(16);
    line-height: rem(22);

    @media screen and (max-width: 630px) {
        margin-bottom: rem(22);
    }
}

.gallery__row {
    position: relative;
    z-index: 50;
}

.gallery__indicators-wrapper {
    float: right;
    text-align: right;

    @include media(500px) {
        display: none;
    }

    @include media($medium-screen-up) {
        display: block;
        @include span-columns(3 of 12);
    }
}

.gallery__clear {
    clear: both;
}

.gallery__arrows {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    z-index: 100;

    @media screen and (max-width: 399px) {
        svg {
            height: 30px;
            width: 20px;
        }
    }
}

.gallery__arrows--left {
    left: 0px;
}

.gallery__arrows--right {
    right: 0px;
}

.gallery__cards-wrapper {
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;

    @media screen and (max-width: 670px) {
        padding-left: 38px;
        padding-right: 38px;
    }

    @media screen and (max-width: 399px) {
        padding-left: 22px;
        padding-right: 22px;
    }
}

.gallery__gallery-wrapper {
    @include span-columns(9 of 12);
    padding-top: rem(30);
}

.gallery__sub-gallery-wrapper {

    margin-top: rem(30);

    @media (min-width: 500px) {
        @include span-columns(12);
    }

    @include media($large-screen-up) {
        @include span-columns(10);
    }

}

.gallery__sub-gallery-wrapper {
    .gallery__action {
        @include span-columns(9.5);
    }
}

.gallery__action {
    clear: both;
    text-align: center;

    .button {
        max-height: 40px;
    }
}

.gallery__back-to {
    display: block;
}

.gallery__with-sub-titlte {
    .gallery__back-to {
        margin-bottom: rem(100);
    }
}

.gallery__back-to-icon {
    float: left;
}

.gallery__back-to-text {
    float: left;
    margin-left: rem(8);
    margin-top: rem(-1);
}

.gallery__sidebar {
    display: none;

    @include media($large-screen-up) {
        display: block;
        @include span-columns(3);
        position: relative;
        z-index: 100;
    }

    h3.step-title {
        font-size: rem(24);
        padding-bottom: rem(22);
        border-bottom: 1px solid #D0D0D1;
    }
}

.gallery__microproject-name {
    font-family: Basetica-Medium, sans-serif;
    color: $green;
}

.filter__list-title {
    font-size: rem(14);
    font-family: Basetica-Bold, sans-serif;
    line-height: 18px;
    margin-bottom: 10px;
}

.filter__actions {
    li {
        margin-bottom: 6px;
    }
}

.filter__action {
    background-color: #E7E7E7;
    color: #5F5F5F;
    display: inline-block;
    max-height: 28px;
    font-size: rem(12);
    line-height: 20px;
    padding: 4px 9px 4px 9px;
}

.filter__action--active {
    background-color: $green;
    color: #E7E7E7;
}

@keyframes outlinefocus {
  0% {
    outline-color: none;
    outline-width: 0;
  }
  70% {
    outline-color: none;
    outline-width: 0;
  }
  100% {
    outline-color: blue;
    outline-width: 1;
  }
}


.slick-slide:focus {
    -webkit-animation: outlinefocus 5s; /* Safari 4+ */
    -moz-animation:    outlinefocus 5s; /* Fx 5+ */
    -o-animation:      outlinefocus 5s; /* Opera 12+ */
    animation:         outlinefocus 5s; /* IE 10+, Fx 29+ */
    animation-iteration-count: 1;
}
.alert__message {
    box-shadow: 0 2px 4px 0 #5A5A5A;
    position: fixed;
    padding: rem(25);
    top: rem(52);
    z-index: 500;
    margin-left: rem(20);
    margin-right: rem(20);

    a {
        color: $mouse-white;
    }

    @include media($medium-screen-up) {
        padding: rem(15);
        width: rem(489);
        right: rem(53);
        width: rem(489);
    }

    .message__icon-close {
        background: none;
        padding: 0;
        position: absolute;
        margin: 0;
        right: rem(15);
    }

    &.message__success {
        background: $mouse-green;
        border: $mouse-dark-green 3px solid;
    }

    &.message__error {
        background: $mouse-red;
        border: $mouse-dark-red 3px solid;
    }

    .message__icon {
        display: none;
        float: left;
        margin-right: rem(24);
        height: rem(60);
        width: rem(60);

        img {
            height: auto;
            width: 100%;
        }

        @include media($medium-screen-up) {
            display: block;
        }
    }

    .message__content {
        color: $mouse-white;
        font-size: rem(20);
        line-height: rem(26);
        padding: 0 rem(60) rem(15) rem(10);
        padding-left: 0;
        padding-right: 0;
        margin-top: rem(25);

        @include media($medium-screen-up) {
            padding-left: rem(10);
            padding-right: rem(10);
        }
    }

    .message__text {
        text-align: left;
        z-index: 50;
    }
}

.message-fade-enter-active, .message-fade-leave-active {
  transition: opacity 0.75s;
}
.message-fade-enter, .message-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
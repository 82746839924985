.slider__wrapper {
    height: 302px;
    overflow: hidden;
}

.slick-slide {
    margin-left: 5px;
    padding-bottom: 5px;

    @media screen and (max-width: 399px) {
        margin-left: 0px;
    }
}

.slick-dots {

    float: right;

    li {
        float: left;
        margin-left: 12px;
    }

    button {
        background-color: $lightgreen;
        border: 2px solid $mediumgreen;
        border-radius: 15px;
        display: inline-block;
        height: 15px;
        outline: none;
        overflow: hidden;
        padding: 0;
        text-indent: -999px;
        width: 15px;
    }

    .slick-active {
        button {
            background-color: $green;
            border: 2px solid $green;
        }
    }
}
// Tables w/ Pagination
//
// See general table above for basic table information. This table includes pagination. Note, there are key accessibility elements in the pagination, including the aria values set here:  ```<nav class="table__pagination" role="navigation" aria-label="Pagination Navigation">``` and ```<a href="#" aria-label="Current Page, Page 3" aria-current="true" class="table__pagination__link table__pagination__link--active">```
//
// Markup: tables_paginated.html
//
// Styleguide Components.Tables.paginated

.table__paginated {
	margin-bottom: 0;
	width: 100%;
	word-wrap: break-word;
	display: table;

    tr > td:last-of-type {
        min-height: 41px;
    }
}

.table__paginated td {
	vertical-align: top;
	@include media($medium-screen-up) {
		vertical-align: middle;
	}
}

.table__paginated td.table__cell__valign-top {
	@extend .table__cell__valign-top;
}

.table__paginated td.table__cell__valign-bottom {
	@extend .table__cell__valign-bottom;
}

.table__paginated td.table__cell__valign-top-small {
	vertical-align: top;
	@include media($medium-screen-up) {
		vertical-align: middle;
	}
}

.table__pagination {
	@include row();
	width: 100%;
	background-color: $green;
	font-family: $Basetica-Medium;
	font-size: rem(16);
	padding: rem(23) rem(15) rem(15) rem(15);
}

.table__pagination__prev {
	@include span-columns(6);
	@include media($medium-screen-up) {
		@include span-columns(3);
	}
}

.table__pagination__next {
	@include span-columns(6);
	text-align: right;
	@include media($medium-screen-up) {
		@include span-columns(3);
	}
}

.table__pagination__numbers {
	display: none;
	@include media($medium-screen-up) {
		display: block;
		@include span-columns(6);
	}
}

.table__pagination__row {
	text-align: center;
}

.admin__wrap .pagination {
    @extend .table__pagination__row;
}

.table__pagination__list {
	display: inline-block;
    width: 36px;
}

.admin__wrap .pagination li {
    @extend .table__pagination__list;
}

.table__pagination__link {
	color: $white;
}

.admin__wrap .pagination li a {
    @extend .table__pagination__link;
}

.table__pagination__link--active {
    color: $mediumgreen;
}

.admin__wrap .pagination .active {
    @extend .table__pagination__link--active;
}

.icon__group {
	display: table;
}

.icon__group a {
	float: left;
}

.icon__group__icon {
	vertical-align: middle;
  	display: table-cell;
  	line-height: 0;
}

.icon__group__icon-top-small {
  	display: table-cell;
  	line-height: 0;
  	vertical-align: top;
	@include media($medium-screen-up) {
		vertical-align: middle;
	}
}

.icon__group__text {
	vertical-align: middle;
  	display: table-cell;
}

.icon__group__text-top-small {
  	display: table-cell;
	vertical-align: top;
	@include media($medium-screen-up) {
		vertical-align: middle;
	}
}

.icon__group div:first-child {
	padding-right: rem(10);
}

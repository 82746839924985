// Project Cards
//
// The basis for project cards
//
// Markup: card-project.html
//
// Styleguide Components.Cards.project

.playlist-content {
    margin-top: rem(15);
    @include media($large-screen-up) {
        margin-top: rem(90);
    }
}

.filter-results {
    margin-bottom: rem(27);
    margin-top: 0;
    color: $black;
    font-size: rem-calc(18);
    @include media($large-screen-up) {
        margin-top: 0;
    }
}

.card-padding {
    @include padding(0 15px);
}

#project-list {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.card {
    position: relative;
    background: $white;
    box-shadow: 0 2px 4px rgba($black,.5);
    border-bottom: 12px solid $green;
    margin-bottom: rem(32);

    @include span-columns(10 of 11);

    @media (min-width: 500px) {
        @include span-columns(5 of 11);
    }

    @include media($medium-screen-up) {
        @include span-columns(3 of 10);
    }

    h2 {
        font-size: rem(22);
        line-height: rem(28);
        @include margin(0 0 8px 0);
        @include padding(15px 15px 0 15px);

        a {
            color: $black;
            text-decoration: none;
        }
    }

    h3 {
        color: $mediumGray;
        font-size: rem(13);
        @extend .card-padding;
        @extend .Basetica-Bold;
    }

    .course-name {
        @extend .card-padding;
        @include margin(0 0 12px 0);
    }

    .teaser {
        @extend .card-padding;
        display: block;
        margin-bottom: rem(18);
        font-size: rem(14);
        line-height: rem(19);
    }

    .card-tags {
        margin-bottom: rem(37);
        display: block;
        font-size: rem(12);
        @extend .card-padding;
        float: left;

        &:last-child {
            margin-bottom: rem(10);
        }

        li {
            float: left;
            @include padding(2px 7px);
            @include margin(0 6px 7px 0);
            background: $mouse-tag-gray;
            color: #5F5F5F;
        }
    }

    .add-to-group,
    .remove-from-group {
        background: $green;
        display: block;
        color: $white;
        font-size: rem(13);
        text-align: center;
        @include padding(10px);
        @extend .Basetica-Medium;
        position: absolute;
        bottom: -12px;
        width: 100%;

        .fa {
            color: $white;
            padding-left: rem(3);
            vertical-align: middle;
        }
    }

    .inactive-group-button {
        @extend .add-to-group;

        cursor: default;
        background: $mouse-tag-gray;
        color: #5F5F5F;
    }

    a.project-image {
        height: 190px;
        width: 100%;
        float: left;
        overflow: hidden;
        margin-bottom: rem(15);
        position: relative;

        @include media($medium-screen-up) {
            height: 172px;
        }

        img {
            object-fit: cover;
            width: 100%;
            top: -9999px;
            bottom: -9999px;
            left: -9999px;
            right: -9999px;
            margin: auto;

            @media (min-width: 500px) {
                height: 100%;
                min-width: 100%;
                max-width: 100%;
                width: auto;
            }

            @include media($medium-screen-up) {
                height: 100%;
                min-width: 100%;
                max-width: 100%;
                width: auto;
            }
        }
    }

// Playlist Cards
//
// The basis for project cards
//
// Markup: card-playlist.html
//
// Styleguide Components.Cards.playlist

    &.playlist-card {
        @include span-columns(12 of 12);
        @include omega(1n);

        @media (min-width: 500px) {
            @include omega-reset(1n);
            @include span-columns(6 of 12);
            @include omega(2n);
        }

        @include media($medium-screen-up) {
            @include omega-reset(2n);
            @include span-columns(4 of 12);
            @include omega(3n);
        }

        @include media($large-screen-up) {
            @include omega-reset(3n);
            @include span-columns(3 of 12);
            @include omega(4n);
        }

        h2.small-card {
            margin-bottom: rem(80);
        }

        h3 {
            color: $black;
            font-size: rem(14);
        }

        p {
            font-size: rem(14);
            line-height: rem(19);
            @extend .card-padding;
            margin-bottom: rem(19);

            &.no-badge {
                margin-bottom: rem(80);
            }
        }

        .fa-arrows {
            position: absolute;
            top: 0;
            right: 0;
            padding: rem(5);
            background: $black;
            border-radius: 0 3px 3px 3px;
            color: #D0D0D1;
            cursor: move;
        }

        input {
            margin-bottom: 5rem;
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    .badges {
        @extend .card-padding;
        float: left;
        margin-bottom: rem(20);

        h3 {
            border-top: 1px solid #D0D0D1;
            padding-left: 0;
            margin-bottom: rem(25);
            padding-top: rem(20);
        }

        ul {
            float: left;
        }

        li {
            @include span-columns(1 of 3);
        }

        img {
            height: auto;
        }
    }
}

.project-step {
    text-align: center;
    margin-bottom: rem(25);
}

.project-title-row {
    @include row();
    position: relative;
    padding-bottom: rem(6);
    border-bottom: 1px solid #D0D0D1;
    margin-bottom: rem(35);
    line-height: 24px;
    vertical-align: middle;
    position: relative;

    h1.project-title {
        text-align: center;
        margin-bottom: rem(10);
        @include media($medium-screen-up) {
            @include span-columns(9 of 9);
            padding-left: rem(175);
            padding-right: rem(175);
        }
    }

    .switch {
        @include media($medium-screen-up) {
            position: absolute;
        }
        right: 0;
        text-align: center;
        margin-bottom: 0;

    }

    .switch span {
        color: #5F5F5F;
        font-weight: 100;
        font-size: rem(11);
        margin-right: rem(5);
    }
    .switch label{
        @include media($medium-screen-up) {
            padding: 0;
            margin: 0;
        }
    }
}

.project-switch {
    span {
        color: $bodyGray;
        font-weight: 100;
        font-size: rem(16);
        line-height: rem(22);
        margin-right: rem(5);
    }

    label {
        @include media($medium-screen-up) {
            padding: 0;
            margin: 0;
        }
    }
}

.step-header {
    @include row();
    margin-bottom: rem(35);

    .previous-step,
    .next-step {
        @include span-columns(1 of 8);
        button {
            color: $green;
            background: transparent;
            padding: rem(3) rem(10);
            margin-bottom: 0;
            border-radius: 0;
            .fa {
                font-size: rem(23);
                @include media($large-screen-up) {
                    font-size: rem(19);
                }
            }
        }
    }

    .next-step {
        text-align: right;
    }

    .title {
        @include span-columns(6 of 8);
        text-align: center;
        h2.step-title {
            font-size: rem(24);
            line-height: rem(28);
            margin-bottom: 0;

            span.step-order {
                @extend .Basetica-Medium;
            }
        }
    }
}

.step {
    p {
        line-height: rem(21);
        @include media($medium-screen-up) {
            font-size: rem(16);
            line-height: rem(22);
        }
    }
}

.facilitator_note div {
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: left;
    background: #D4F3EB;
    margin-bottom: rem(34);
    border-radius: 4px;
    color: #3A3A3A;
    width: 95%;
    padding: rem(15);

    h3 {
        font-size: rem(13);
        color: $green;
        @extend .Basetica-Bold;
        margin-bottom: rem(5);
    }

    p {
        margin-bottom: rem(12);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.project-steps {

    li {
        padding: 12px 0 15px 0;
        border-bottom: 1px solid #D0D0D1;

    }

    img {
        vertical-align: top;
        padding-right: rem(8);
    }

    a {
        color: #8A8A8D;
        line-height: rem(21);
    }

    a.active {
        color: #000;
        line-height: rem(21);
    }
}

.all-evidence {
    color: #8A8A8D;
    padding-top: rem(9);
    display: block;
    line-height: rem(21);
}

.evidence-prompt {
    margin-bottom: 1rem;
}

/***** ASSET WORKSPACE *****/

.asset-workspace {

    clear: both;

    h3 {
        background: $black url(/img/asset-bg.svg) no-repeat right top;
        background-width: 278px;
        color: $white;
        font-size: rem(24);
        @include padding(18px);
        margin-bottom: rem(28);
    }

    .asset-block {
        @include padding(23px 18px);
        background: #F7F7F7;
        margin-bottom: rem(30);

        .controls {
            @include row();
            margin-bottom: rem(37);

            .asset-icon {
                @include span-columns(2 of 8);
                color: $green;
                i {
                    width: rem(24);
                }
            }

            .alter {
                @include span-columns(6 of 8);
                font-size: rem(14);
                text-align: right;

                a {
                    color: $green;
                    padding-left: rem(20);
                }
            }
        }

        .reorder {
            margin-bottom: 1rem;

            input[type="text"] {
                width: rem(75);
            }
        }

    }

    .add-media {

        label {
            display: inline-block;
            width: 32%;
            @include media($medium-screen-up) {
                width: auto;
            }

            &:last-of-type {
                button,
                .button {
                    margin-right: 0;
                }
            }
        }

        button,
        .button {
            width: 100%;
            @include media($medium-screen-up) {
                margin-right: rem(15);
                width: rem(160);
            }
        }
    }

}

.original-asset {
    margin-bottom: rem(30);

}

.project-navigate {
    @include row();
    border-top: 1px solid #D0D0D1;
    padding-top: rem(38);

    .button,
    button {
        min-width: rem(115);
    }

    .previous-step {
        @include span-columns(4 of 8);
    }

    .next-step {
        @include span-columns(4 of 8);
        text-align: right;
    }
}

.project-mobile-filter {

    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;

    .dropdown-menu {
        display: block;
    }
}

.no-pack-access {

    border-bottom: 12px solid #d9f2ec;
    min-height: rem(520) !important;

    img {
        opacity: 0.2;
    }

    h2 {
        a {
            color: #979797;
        }

        margin-bottom: rem(30);
    }

    .teaser {
        color: #e1e1e1;
    }

    h3 {
        color: #e1e1e1 !important;
    }

    .course-name {
        color: #e1e1e1;
    }

    .no-badge {
        color: #e1e1e1 !important;
    }

}

.no-access__description {

    padding: 0 rem(24);

    svg {
        margin-bottom: rem(7);
    }

    p {
        color: $mouse-green;
        font-size: rem(16) !important;
        line-height: rem(26) !important;
        @extend .Basetica-Medium;
        padding: 0 !important;
    }
}

#group{

	hr{
		color: $mediumGray;
	}

	.form-row{
		margin-bottom: 1rem;
	}

	// top section of group page
	.enrollment_code{
		color: $mediumGray;
	}

	.options{
		margin-top: rem(68);
		text-align: right;
	}

	.edit{
		color: $green;
	}
	.delete{
		color: $red;
	}

	.group_info {
		color: $bodyGray;
		margin-bottom: 20px;
		line-height: rem(22);
	}

	// main titles on page
	small,
	small a{
		color: $green;
		font-size: 0.875rem;
		display: inline-block;
		margin-left: rem(10);
	}

	.group-details {
		@include span-columns(12);
		margin-bottom: rem(20);

		@include media($medium-screen-up) {
			@include span-columns(6 of 12);
			margin-bottom: 0;
		}
	}

	.group-edit {
		@include span-columns(12);
		@include media($medium-screen-up) {
			@include span-columns(6 of 12);
			margin-top: rem(68);
   			text-align: right;
		}
	}

}

#playlist_update_message {
	margin-bottom: rem(20);
}

.clear-font {
	font-family: verdana, sans-serif;
	font-weight: bold;
}

.playlist > div {
//     height: 100px;
    margin-bottom: 20px;
    border: 1px solid rgba(10, 10, 10, .25);
}

.playlist-reorder {
    .project {
        position: relative;
        margin-bottom: 20px;
        border: 1px solid rgba(10, 10, 10, .25);
    }
}

.asset-processing {
    color: $red;
    text-align: right;
}

.group-tab {
    @include outer-container();
    padding-top: 20px;

    .table__pagination {
        margin-bottom: rem(20);
    }

    .status--needs-review {
        cursor: pointer;
    }

    table caption {
        color: $mouse-black;
        font-size: 24px;
    }

    .manage-users {
        margin-top: 20px;
    }

    .header__admin--student {
        .header__admin__text {
            margin-bottom: 0;
        }

        .header__admin__text__title {
            margin-bottom: 0;
        }
    }

    .table__actions .fa {
        font-size: 15.5px;
    }

    .collaborators {
        .profile__pic-member {
            display: inline-block;
            margin-right: rem(3);
        }

        .icon__group__text {
            color: $mouse-green;
        }
    }
}

.tab-content--padding {
    padding: rem(20);
}

.manage-members {
    padding-left: rem(20);
    padding-right: rem(20);
}

.group-fixed-container {
    max-width: rem(800);
}

.group-title--area {
    border-bottom: 1px solid #D0D0D1;
    margin-bottom: rem(26);

    .group-button-right {
        text-align: left;
    }

    h1 {
        font-size: rem(30);
        line-height: rem(36);
        padding-top: rem(12);
        margin-bottom: rem(12);
        @include media($medium-screen-up) {
            padding-top: 0;
        }
    }

    @include media($small-screen-up) {
        h1 {
            float: left;
            margin-bottom: 0;
        }

        .group-button-right {
            text-align: right;
        }
    }
}
.message__outdated {
    background: #2c2c2c;
    border-bottom: 2px solid #979797;
    color: $mouse-white;
    display: none;
    padding: rem(20);
    text-align: center;

    p {
        padding: 0;
        margin: 0;
        font-size: rem(16);
        line-height: rem(20);
    }
}

.message__outdated-show {
    display: block;
}

.message__alert-icon {
    margin-bottom: rem(15);
}

.response-loading {
    margin-top: rem(50);
    text-align: center;

    h2 {
        font-size: rem(22);
        font-weight: bold;
        margin-bottom: rem(25);
    }
}
// Tabs
//
// The basis for tabs that appear on the site
//
// Markup: tabs.html
//
// Styleguide Components.Tabs

.tab-background {
	background: $black;
	height: rem(36);
	width: 100%;
}
.tab-container{
	@include span-columns(11);
	@include shift(.5);
    padding: 0;
	position: relative;
	z-index: 99;

	ul{

		display: table;
        float: left;
        list-style-type: none;
        padding-left: 0;
        width: 100%;
        vertical-align: bottom;

		li {

            padding: rem(12) rem(28);
			display: inline-block;
			background-color: $green;
            border-radius: 2px 2px 0 0;
            font-size: rem(15);
            padding: rem(12) rem(26) rem(9) rem(26);
            @include margin(0 3px 0 0);

            @include media($small-screen-up) {
                padding: rem(12) rem(28) rem(9) rem(28);
                @include margin(0 10px 0 0);
            }

    		a {
				color: $mouse-white;
    		}

            &:last-child {
                margin-right: 0;
            }
		}

		li.active{
			background-color: #f7f7f7;
			a{
				color: $black;
			}
		}
	}
}
.tab-content{
	@include span-columns(12)
	// @include shift(.5);
	@include padding(20px 20px 0 20px);
	display: block;
	clear: both;
}

.group-page {
    @media (max-width: 569px) {
        .tab-container {
            background-color: #F7F7F7;
            border-radius: 2px;

            ul {



                li {
                    background: none;
                    margin: 0;
                    border-radius: 0;
                    padding: 12px 20px;

                    a {
                        color: #8A8A8D;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    &.active {
                        a {
                            color: #00A886;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 438px) {
        .tab-container {
            ul {
                li {
                    padding: 12px 9px;
                }
            }
        }
    }
}

.group-page__tabs {

    @media (max-width: 569px) {

        background-color: #F7F7F7;
        border-radius: 2px 2px 0 0;

        ul {

            padding-left: rem(21);
            text-align: center;

            li {
                background: none;
                margin: 0;
                border-radius: 0;
                padding: 12px 20px;

                a {
                    color: #8A8A8D;
                    font-size: 14px;
                    line-height: 18px;
                }

                &.active {
                    a {
                        color: #00A886;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    @media (max-width: 438px) {
        ul {
            li {
                padding: 12px 9px;
            }
        }
    }
}

.tab__back-button {

    margin-top: -20px !important;
    margin-bottom: 7px !important;
    padding-bottom: 0 !important;

    a {
        height: rem(20);
    }
}

$base-spacing: 1.5em !default;
$flashes: (
  "alert": #fff6bf,
  "error": #fbe3e4,
  "notice": #e5edf8,
  "success": #e6efc2,
) !default;

@each $flash-type, $color in $flashes {
  .flash-#{$flash-type} {
    background-color: $color;
    color: shade($color, 60%);
    display: block;
    margin-bottom: $base-spacing / 2;
    padding: $base-spacing / 2;
    text-align: center;

    a {
      color: shade($color, 70%);
      text-decoration: underline;

      &:focus,
      &:hover {
        color: shade($color, 90%);
      }
    }
    p {
	  margin-bottom: 0;  
    }
  }
}

.home__header {
    background: $mouse-black url(/img/home-header-background.jpg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding-top: rem(38);
    padding-bottom: 0;
    text-align: center;

    @media screen and (min-width: 820px) {
        padding-bottom: rem(40);
        padding-top: rem(65);
    }
}

.header__brand {
    margin-bottom: rem(15);

    svg {
        width: rem(224);

        @include media($medium-screen-up) {
            width: rem(360);
        }
    }
}

.header__actions {
    @media screen and (max-width: 768px) {
        margin-bottom: rem(34);
    }

    margin-bottom: rem(40);

    .button {
        margin-bottom: 0;
    }
}

.header__description {
    letter-spacing: rem(0.4);
    margin: 0 auto rem(22) auto;
    padding-left: rem(20);
    padding-right: rem(20);
    font-size: rem(16);
    line-height: rem(24);

    @media screen and (min-width: 525px) {
        width: 80%;
    }

    @include media($medium-screen-up) {
        font-size: rem(24);
        line-height: rem(34);
    }

    @media screen and (min-width: 900px) {
        width: rem(800);
    }

    @media screen and (min-width: 1200px) {
        width: 100%;
    }
}

.home__button {
    padding: 0;
    @extend .Basetica-Medium;
    font-size: rem(16);
    line-height: rem(15);
    height: rem(36);
    padding-left: 33px;
    padding-right: 33px;

    &:nth-child(2) {
        margin-right: rem(20);
    }

    @include media($medium-screen-up) {
        font-size: rem(20);
        height: rem(39);
    }

    span {
        display: table-cell;
        height: rem(39);
        vertical-align: middle;
    }

    svg {
        vertical-align: bottom;

        @media screen and (max-width: 768px) {
            height: rem(15);
        }
    }
}

.home_slider-button {
    margin-bottom: 0;
    width: auto;
}

.header__video {
    background: $mouse-black;
    margin: auto;
    padding: 0;
    width: 100%;

    @media screen and (min-width: 820px) {
        padding: rem(12) rem(12) rem(38) rem(12);
        width: rem(805);
    }
}

.video__player {
    height: auto;
    margin-bottom: rem(28);

    .video-js {
        height: auto;
        width: 100%;
    }

    iframe {
        left:0;
        top:0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.video__content {
    text-align: left;
    padding-left: rem(30);
    padding-right: rem(30);
    padding-bottom: rem(50);

    @media screen and (min-width: 820px) {
        padding: 0 rem(60) 0 0;
    }

    p {

        font-size: rem(16);
        line-height: rem(22);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(28);
        }

        &:last-child {
            margin-bottom: 0;
        }

    }

    a {
        color: $mouse-white;
        text-decoration: underline;
    }
}

.supporting__slider {
    background: $mouse-white;
    padding: rem(47) rem(35) rem(30) rem(35);
    position: relative;

    @media screen and (min-width: 1090px) {
        padding-bottom: rem(40);
        padding: rem(60) 0 rem(40) 0;
    }

    h1 {
        font-size: rem(24);
        line-height: rem(32);
        letter-spacing: rem(0.4);
        margin-bottom: rem(34);
        text-align: center;

        @include media($medium-screen-up) {
            font-size: rem(36);
            line-height: rem(42);
        }
    }
}

.supporting__slider-wrapper {
    margin: auto;
    width: 100%;

    @include media($medium-screen-up) {
        width: 90%;
    }

    @media screen and (min-width: 1080px) {
        width: rem(960);
    }

    .slick-dots {
        float: none;
        margin-top: rem(49);
        text-align: center;
        display: none !important;

        @include media($large-screen-up) {
            display: block !important;
        }

        li {
            float: none;
            display: inline;

            button {
                background: $mouse-white;
                border: 1px solid #979797;
                height: rem(22);
                width: rem(22);
            }


            &.slick-active {
                button {
                    background: $mouse-green;
                    border: 1px solid $mouse-green;
                }
            }
        }
    }
}

.home__arrows {
    position: absolute;
    cursor: pointer;
    height: rem(34);
    width: rem(34);
    top: rem(240);
    z-index: 100;

    @media screen and (min-width: 440px) {
        top: rem(320);
    }

    @include media($medium-screen-up) {
        top: rem(220);
    }

    @include media($large-screen-up) {
        top: rem(295);
        height: rem(78);
        width: rem(47);
    }
}

.home__prev {
    background: url(/img/home-triangle-left-mobile.svg);
    background-repeat: no-repeat;
    background-position: center;
    left: rem(10);

    @include media($large-screen-up) {
        background: url(/img/home-triangle-left.svg);
    }

    @media screen and (min-width: 1170px) {
        left: rem(60);
    }
}

.home__next {
    background: url(/img/home-triangle-right-mobile.svg);
    background-repeat: no-repeat;
    background-position: center;
    right: rem(10);

    @include media($large-screen-up) {
        background: url(/img/home-triangle-right.svg);
    }

    @media screen and (min-width: 1170px) {
        right: rem(60);
    }
}

.slider__image {
    float: none;
    margin: 0;

    @include media($medium-screen-up) {
        float: left;
        margin-right: rem(20);
        width: rem(350);
    }

    @media screen and (min-width: 800px) {
        width: rem(375);
    }

    @media screen and (min-width: 1170px) {
        width: rem(560);
        margin-right: rem(54);
    }

    img {
        width: 100%;
    }

}

.slider__content {

    padding-right: 0;
    padding-top: rem(20);
    overflow: hidden;

    @include media($medium-screen-up) {
        padding-top: 0;
    }

    h2 {
        @extend .Basetica-Medium;
        font-size: rem(18);
        line-height: rem(25);
        margin-bottom: rem(10);

        @include media($medium-screen-up) {
            font-size: rem(24);
            line-height: rem(30);
        }
    }

    p {
        font-size: rem(16);
        line-height: rem(24);

        @include media($medium-screen-up) {
            font-size: rem(18);
            line-height: rem(25);
        }
    }
}

.slider__cta {
    .cta__content {
        color: $mouse-green;
        display: block;
        @extend .Basetica-Medium;
        font-size: rem(18);
        line-height: rem(28);
        margin-bottom: rem(12);

        @include media($medium-screen-up) {
            font-size: rem(20);
            line-height: rem(28);
        }
    }
}

.mouse__news {
    padding-bottom: rem(30);
    padding-top: rem(30);

    @include media($medium-screen-up) {
        padding-bottom: rem(60);
        padding-top: rem(60);
    }

    h2 {
        font-size: rem(24);
        line-height: rem(42);
        text-align: center;
        margin-bottom: rem(5);

        @include media($medium-screen-up) {
            font-size: rem(36);
        }
    }

}

.news__hilight {
    @extend .Basetica-Medium;
    font-size: rem(14);
    line-height: rem(20);
    margin-bottom: rem(28);
    text-align: center;

    @include media($medium-screen-up) {
        margin-bottom: rem(40);
    }

    p {
        display: inline-block;
        margin: 0;
    }
}

.news__hilight-icon {
    margin-right: rem(5);
}

.news__cards {
    margin-left: 0;
    padding: 0;
    list-style: none;
    padding-left: rem(39);
    padding-right: rem(39);

    @include media($medium-screen-up) {
        display: flex;
    }

    @media screen and (min-width: 930px) {
        @include shift(1);
    }

    @include media($large-screen-up) {
        padding-left: 0;
        padding-right: 0;
    }

    .news__card {
        background: $mouse-white;
        box-shadow: 0 rem(2) rem(4) $mouse-footer-gray;
        float: left;
        padding-bottom: rem(35);
        margin-bottom: rem(18);
        width: 100%;

        &:last-child {
            margin-right: 0;
        }

        @include media($medium-screen-up) {
            @include span-columns(3 of 9);
        }

        @media screen and (min-width: 930px) {
            @include span-columns(3 of 10);
        }

        p {
            font-size: rem(15);
            line-height: rem(22);

            @media screen and (min-width: 930px) {
                font-size: rem(18);
                line-height: rem(26);
            }
        }
    }
}

.news__card-image {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    margin-bottom: rem(26);
    position: relative;

    img {
        width: 100%;
    }
}

.news__card-content {
    padding-left: rem(20);
    padding-right: rem(20);
    position: relative;

    .news__card-date {
        font-size: rem(12);
        line-height: rem(18);
        margin-bottom: rem(14);
    }

    .news__card-title {
        font-size: rem(22);
        line-height: rem(28);
        text-align: left;
        margin-bottom: rem(20);
    }

    .news__card-text {
        color: $mouse-body-gray;
        font-size: rem(16);
        line-height: rem(25);

        @include media($medium-screen-up) {
            font-size: rem(18);
            line-height: rem(26);
        }
    }

    .news__card-action {
        color: $mouse-body-gray;
        @extend .Basetica-Bold;
        font-size: rem(16);
        line-height: rem(25);

        @include media($medium-screen-up) {
            font-size: rem(18);
            line-height: rem(26);
        }
    }
}

.news__featured-label {
    background: $mouse-green;
    border-radius: rem(8);
    color: $mouse-white;
    font-size: rem(18);
    line-height: rem(23);
    padding: rem(10) rem(5);
    position: absolute;
    top: rem(16);
    left: rem(13);
}

.news__featured-star {
    position: absolute;
    bottom: rem(-37);
    right: rem(15);
}

.news__more-button {
    clear: both;
    font-size: rem(18);
    line-height: rem(23);
    text-align: center;

    p {
        padding-top: rem(10);

        @include media($medium-screen-up) {
            padding-top: rem(35);
        }
    }
}

// Navigation
//
// Main navigation
//
// Markup: navigation.html
//
// Styleguide Components.Navigation



html {
	overflow-y: scroll
}


header {
    padding: rem(12) 0 rem(7);
    background: $black;
    color: $white;
    height: rem(79);

	@include media($large-screen-up) {
		background: $black url(/img/header-art.svg) no-repeat 70px top;
		background-size: auto rem(120);
	}

	.has-group {

        max-width: none;

		.branding {
			display: none;

			@include media($medium-screen-up) {
				display: block;
				@include span-columns(2.5);
				@include shift(.5);
			}
		}
		.grouping {
			display: none;

			@include media($medium-screen-up) {
				display: block;
				@include span-columns(3.5);
				@include shift(.5);
			}
		}

		.menu-actions {
			@include span-columns(12);
			@include media($medium-screen-up) {
				@include span-columns(5);
			}

            padding-right: 0;

            @include media($medium-screen-up) {
                padding-right: 4.2345276873%;
            }
		}
	}

    .has-no-active-groups {
        @extend .has-group;
    }

	.branding {
		@include span-columns(6.5);
		@include shift(.5);

		@include media($medium-screen-up) {
			display: block;
			@include span-columns(7.5);
			@include shift(.5);
		}

		@include media($large-screen-up) {
			display: block;
			@include span-columns(6);
			@include shift(1);
		}

		.logo {
			width: 145px;
			padding-top: rem(17);
		}
	}

	.grouping{
		@include span-columns(3);
		margin-top: rem(20);
		text-align: center;

		@include media($medium-screen-up) {
			display: block;
			@include span-columns(3);
			@include shift(.5);
		}

		@include media($large-screen-up) {
			display: block;
			@include span-columns(3);
			@include shift(1);
		}

		a {
			width: 145px;
			color: $white;
		}
	}

	.menu-actions {
		@include span-columns(4);
		@include media($large-screen-up) {
			@include span-columns(6);
		}

        float: right;

		ul {
			float: right;
			list-style-type: none;
			vertical-align: bottom;
			width: 100%;
			display: table;

            @include media($medium-screen-up) {
				width: auto;
			}

			li.primary {
				width: 20%;
				display: inline-block;
				text-align: center;
				font-size: rem(14);
				height: rem(65);
				position: relative;
				display: table-cell;
				vertical-align: top;
				@include media($medium-screen-up) {

					width: rem(75);
                    &.menu-notifications {
                      width: rem(65);
                    }
				}


				&:last-child {
					padding-right: 0;
				}

				&:first-child {
					padding-left: 0;
				}

				a {
					color: #E7E7E7;
					display: block;
					width: 100%;

					&:hover {
						.icon,
						.text {
							fill: $green;
						}
						.lines {
							stroke: $green;
						}
					}
				}

				img {
					width: 33px;

					&.profile-pic {
						border-radius: 50%;
						width: 40px;
						height: 40px;
						display: block;
						margin: 0 auto;
						margin-bottom: rem(2);
						margin-top: rem(-3);
					}
				}

				svg {
					height: 57px;

					&:hover {
						.icon,
						.text {
							fill: $green;
						}
					}
				}

				&.profile {
					vertical-align: top;
					@include media($medium-screen-up) {
						position: relative;
					}
					svg {
						width: 42px;
						height: 12px;
						position: absolute;
						margin-left: auto;
						margin-right: auto;
						left: 0;
						right: 0;
						bottom: rem(10);
					}
				}

				&.group {
					svg {
						height: 57px;
						width: 46px;
					}
				}

				&.project {
					svg {
						height: 59px;
						width: 53px;
					}
				}

				.profile-pic {
					img {
						border-radius: 50%;
						width: 40px;
						height: 40px;
						display: block;
						margin: 0 auto;
						margin-top: rem(-3);
						margin-bottom: rem(2);
					}
				}

				span {
					display: block;
				}
			}
			li.main-dropdown {

				.lines {
					stroke: #E7E7E7;
				}

				a {
					&:hover {
						color: $green;
					}
				}

				.dropdown .dropdown-button:active,
				.dropdown .dropdown-button.no-focus-lines {
					outline: none !important;
				}

				.dropdown .dropdown-button::after,
				.dropdown .dropdown-button-delay::after {
					display: none;
				}
				.dropdown .dropdown-menu {
					background: $black;
					top: rem(63);
					right: rem(-19);
					text-align: right;
					font-size: rem(16);
					@include media($medium-screen-up) {
						width: rem(309);
					}
					li {
						padding: rem(22) 0 rem(11) 0;
						&:last-child {
							padding-bottom: 0;
						}
					}
				}
				svg {
					fill:#E7E7E7;

					&:hover {
						fill: $green;

						.lines {
							stroke: $green;
						}
					}
				}

				.dropdown-button.active,
				.dropdown-button:hover {
					.lines {
						stroke: $green;
					}
					.text {
						fill: $green;
					}
				}

			}
		}
	}

    .no-license {
        float: right;
        width: 150px;
    }

    .has-no-active-groups {
        @media (min-width: 300px) {
            .menu-actions {
                ul {
                    width: 150px;

                    li.main-dropdown {
                        float: none !important;
                    }
                }
            }
        }
    }

	.tab-container{
		@include span-columns(9)
		@include shift(.5);
		@include padding(8px 0 0 0);
		ul{
			float: left;
			list-style-type: none;
			vertical-align: bottom;
			width: 100%;
			display: table;
			li {

				display: inline-block;
				background-color: $green;
				@include padding(12px 28px 12px 28px);
				@include margin(0 10px 0 0);
				border-radius: 2px 2px 0 0;
				font-size: rem(15);
	    		a {
					color: $white;
	    		}
			}
			li.active{
				background-color: $white;
				a{
					color: $black;
				}
			}
		}
	}

}

.router-link-active {
    .icon,
    .text {
        fill: $green;
    }
}

.profile-page {
    header {
        height: auto !important;
    }
}

html.touch header .menu-actions ul li.main-dropdown .dropdown-button:hover .text {
	fill: #E7E7E7;
}

html.touch header .menu-actions ul li.main-dropdown .dropdown-button.active .text {
	fill: $green;
}

html.touch header .menu-actions ul li.main-dropdown .dropdown-button.active .lines {
	stroke: $green;
}

html.touch header .menu-actions ul li.main-dropdown .dropdown-button.hover .lines,
html.touch header .menu-actions ul li.main-dropdown svg:hover .lines {
	stroke: #E7E7E7;
}

.group-bar {
	width: 100%;
	background: #2E2E2E;
	text-align: center;
	font-size: rem(18);
	color: $white;
	padding-top: rem(8);
	padding-bottom: rem(8);
	@extend .Basetica-Medium;

	a {
		color: $white;
	}
}

.body--tab-height {
    header {
        height: rem(99);

        @include media($medium-screen-up) {
            height: rem(77);
        }
    }
}

.no-active-group {
    background-color: #00a885;
    text-align: center;
    height: 53px;
    width: 100%;
    padding-top: 9.5px;


    @include media($medium-screen-up) {
        height: 38px;
    }

    p {
        color: #fff;
        @extend .Basetica-Medium;
        font-size: 14px;
        line-height: 18px;
        padding-left: 33px;
        padding-right: 33px;

        @include media($medium-screen-up) {
            height: 38px;
            font-size: 16px;
            line-height: 19px;
            padding: 0;
        }
    }

    a {
        color: #fff;
        text-decoration: underline;
    }
}
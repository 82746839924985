// Color Palette
//
// The site color palatte
//
// $mouse-red - #FF3333, Mouse Red
// $mouse-black - #000000, Mouse Black
// $mouse-green - #00A886, Mouse Green
// $mouse-blue - #2C328C, Mouse Blue
// $mouse-cool-gray - #8A8A8D, Mouse Cool Gray
// $mouse-body-gray - #3a3a3a, Mouse Body Gray
// $mouse-rule-gray - #D0D0D1, Mouse Rule Gray
// $mouse-tag-gray - #E7E7E7, Mouse Tag Gray
// $mouse-background-gray - #F7F7F7, Mouse Background Gray
// $mouse-dark-red - #D92B2B, Mouse Dark Red
// $mouse-dark-green - #028E6D, Mouse Dark Green
// $mouse-dark-blue - #1A1A6B, Mouse Dark Blue
// $mouse-light-red - #ffebe4, Mouse Light Red
// $mouse-light-green - #ACE2D4, Mouse Light Green
// $mouse-light-green-extra - #e6f6f2, Mouse Extra Light Green
// $mouse-light-blue - #bac6dd, Mouse Light Blue
// $mouse-light-blue-extra - #EEF1F6, Mouse Extra Light Blue
//
// Styleguide Components.Colors


/* Primary Color Palette */
$mouse-red: #FF3333;
$mouse-black: #000000;
$mouse-white: #FFFFFF;

/* Secondary Color Palette */
$mouse-green: #00A886;
$mouse-blue: #2C328C;
$mouse-cool-gray: #8A8A8D;


/* Gray Color Palette */
$mouse-body-gray: #3a3a3a;
$mouse-rule-gray: #D0D0D1;
$mouse-tag-gray: #E7E7E7;
$mouse-background-gray: #F7F7F7;
$mouse-disabled-gray: #DBDBDB;
$mouse-footer-gray: #ADADAD;

/* Tertiary Color Palette */
$mouse-dark-red: #D92B2B;
$mouse-dark-green: #028E6D;
$mouse-dark-blue: #1A1A6B;
$mouse-light-red: #ffebe4;
$mouse-light-green: #ACE2D4;
$mouse-light-green-extra: #e6f6f2;
$mouse-light-blue: #bac6dd;
$mouse-light-blue-extra: #EEF1F6;

.main-menu {
	border-bottom: 1px solid #D0D0D1;
	height: rem(52);
	line-height: rem(52);
	text-align: center;
	font-size: rem(18);
	margin-bottom: rem(15);
	@include media($large-screen) {
		height: rem(65);
		line-height: rem(65);
		margin-bottom: 0;
	}

	&.mobile-project-steps,
	&.mobile-project-filter {
		@include media($large-screen) {
			display: none;
		}
	}

	&.desktop-main-menu {
		display: none;
		@include media($large-screen) {
			display: block;
		}
	}

	li {
		display: inline;
		@include margin(0 5px);
		a {
			background: $white;
			border-radius: 3px;
			@include padding(4px 13px);
			text-decoration: none;
			color: $black;
		}
	}

	.mouse-menu {
		display: none;
		@include media($large-screen) {
			display: block;
		}
	}

	.mobile-dropdown.mobile-steps,
	.mobile-dropdown.mobile-filter {
		display: block;
		@include media($large-screen) {
			display: none;
		}

		.dropdown {
			.dropdown-container {
				width: 100%;
			}

			.dropdown-button,
			.dropdown-button-delay {
				float: none;
				font-size: rem(18);
				line-height: inherit;

				&::after {
					position: relative;
					right: rem(-5);
					color: $black;
					font-size: rem(22);
				}
			}

			.dropdown-menu {
				width: 100%;
				position: relative;
				right: 0;
				top: 0;
				background-color: #F4F4F4;
				box-shadow: 0 4px 4px rgba(#848484, .5);

				li {
					display: block;
					border-bottom: 1px solid #D0D0D1;
					margin: 0;

					&:last-child {
						border-bottom: none;
					}

					&.button-holder {
						border-bottom: none;
					}
				}

				a {
					background-color: transparent;
					color: #8A8A8D;
					font-size: rem(18);
					padding: 0;

                    &.router-link-active {
                        color: $black;
                    }

					&.active {
						color: $black;
					}
				}
			}
		}
	}

	.mobile-dropdown.mobile-filter {
		.dropdown .dropdown-menu li {
			a {
				font-size: rem(16);
			}

			.filter-block {
				h3 {
					@extend .Basetica-Bold;
					line-height: rem(18);
					margin-bottom: rem(15);
					text-transform: uppercase;
				}
				ul {
					border-bottom: 1px solid #D0D0D1;
					margin-bottom: rem(28);
					padding-bottom: rem(28);
					line-height: normal;
				}

				li {
					border-bottom: none;
					display: inline;
					margin-right: rem(7);
					line-height: normal;
					margin-bottom: 0;
				}
				.tag {
					@include padding(5px 7px);
					@include margin(0 0 10px 0);
					font-size: rem(12);
					line-height: rem(18);
					text-decoration: none;
					color: #5F5F5F;
					background: #e7e7e7;
					display: inline-block;

					&:hover,
					&:focus {
						color: #5F5F5F;
					}

					&.active {
						background: $green;
						color: $white;
					}

					&.empty {
						color: #A6A6A6;
						cursor: default;
						background: transparent;

						&:hover,
						&:focus {
							color: #A6A6A6;
						}
					}
				}
			}
		}
	}

}
.back_link{

	padding-bottom: 10px;
	margin-bottom: 30px;
	font-size: rem(16);
	margin-top: rem(25);

	@include media($large-screen-up) {
		margin-top: 0;
	}

	a{
		color: $green;
	}

	hr{
		margin: 0!important;
		padding: 10px 0 0 0;
	}

}
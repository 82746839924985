.dl-menuwrapper {
	min-width: 100%;
	float: left;
	position: relative;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-perspective-origin: 50% 200%;
	perspective-origin: 50% 200%;
	z-index: 1000;
}

.dl-menuwrapper:first-child {
	margin-right: 100px;
}

.dl-menuwrapper button {
	background: transparent;
	border: none;
	width: rem(27);
	height: rem(45);
	text-indent: -900em;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	outline: none;
	margin-bottom: 0;
	margin-left: rem(23);
	padding: 0;
}

.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active {
	background: transparent;
}

.dl-menuwrapper ul {
	background: #252525;
}

.dl-menuwrapper button:after {
	content: '';
	position: absolute;
	width: 100%;
	height: rem(3);
	background: #fff;
	top: rem(8);
	left: 0;
	box-shadow:
		0 10px 0 #fff,
		0 20px 0 #fff;
}

.dl-menuwrapper ul {
	padding: 0;
	list-style: none;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.dl-menuwrapper li {
	position: relative;
	padding: 0 rem(23);

	&.user-account {
		a {
			padding: rem(6) 0;
		}

		ul.dl-submenu li a {
			padding: rem(15) 0;
		}

		img {
			width: 35px;
			height: 35px;
			vertical-align: middle;
			border-radius: 50%;
		}
	}
}

.dl-menuwrapper li li {
	padding: 0;
}

.dl-menuwrapper li a {
	display: block;
	position: relative;
	padding: rem(15) 0;
	font-size: rem(18);
	line-height: rem(20);
	color: #E7E7E7;
	outline: none;
	border-bottom: 1px solid #4A4A4A;
}

.dl-menuwrapper li.dl-back > a {
	padding-left: 0;
	background: rgba(0,0,0,0.1);
}

.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li > a:not(:only-child):after {
	margin-left: rem(10);
	font-family: FontAwesome;
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f0da";
	color: #E7E7E7;
}

.dl-menuwrapper li.dl-back:after {
	position: absolute;
	left: -25px;
	top: 0;
	line-height: rem(50);
	color: #E7E7E7;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
	right: rem(10);
	color: rgba(0,0,0,0.15);
}

.dl-menuwrapper .dl-menu {
	margin: 0;
	position: absolute;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
	transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
	display: none;
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
	display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
	display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
	position: absolute;
	width: 100%;
	left: 0;
	margin: 0;
}

.dl-menu.dl-animate-out-2 {
	-webkit-animation: MenuAnimOut2 0.3s ease-in-out;
	animation: MenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimOut2 {
	0% { }
	100% {
		-webkit-transform: translateX(-100%);
		opacity: 0;
	}
}

@keyframes MenuAnimOut2 {
	0% { }
	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		opacity: 0;
	}
}

.dl-menu.dl-animate-in-2 {
	-webkit-animation: MenuAnimIn2 0.3s ease-in-out;
	animation: MenuAnimIn2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimIn2 {
	0% {
		-webkit-transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes MenuAnimIn2 {
	0% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		opacity: 1;
	}
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
	-webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
	animation: SubMenuAnimIn2 0.3s ease-in-out;
}

@-webkit-keyframes SubMenuAnimIn2 {
	0% {
		-webkit-transform: translateX(100%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes SubMenuAnimIn2 {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		opacity: 1;
	}
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
	-webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
	animation: SubMenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes SubMenuAnimOut2 {
	0% {
		-webkit-transform: translateX(0%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(100%);
		opacity: 0;
	}
}

@keyframes SubMenuAnimOut2 {
	0% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
	}
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
	position: relative;
	opacity: 1;
	-webkit-transform: none;
	transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
	display: block;
}

.no-js .dl-menuwrapper li.dl-back {
	display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
	background: rgba(0,0,0,0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
	content: '';
}
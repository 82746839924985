.full-page-wrap{
	@include padding(25px);
	background-color: #fafafa;
}

.full-page-tabs-wrap{
	background-color: #fafafa;
}

/* layout template */
.brand-text {
    @extend .Basetica-ThinItalic;
    color: $brand;
}

.off-canvas {
    padding: 20px;
}
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

body {
    @extend .Basetica-Regular;
    font-weight: 100;
    background: #fafafa;
    color: $text;
    font-size: rem-calc(16);
    line-height: rem-calc(20);
}

strong {
    font-weight: bold;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

/* Taking place for foundation */
img {
    max-width: 100%;
    height: auto;
    display: inline-block;
}

.hide {
    display: none !important;
}

.callout {
    margin: 0 0 1rem 0;
    padding: 1rem;
    border: 1px solid rgba(10, 10, 10, 0.25);
    border-radius: 0;
    position: relative;
    color: #0a0a0a;

    &.warning {
        background-color: #fff3d9;
    }

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0;
    }

    button {
        background: none;
    }

    .close-button {
        position: absolute;
        color: #8a8a8a;
        right: 1rem;
        top: 0.5rem;
        font-size: 2em;
        line-height: 1;
        cursor: pointer;
    }
}

body {
    -webkit-font-smoothing: antialiased;
}

.clearfix {
    @include clearfix;
}

.intro {
    color: #555;
}

table.full-width {
    width: 100%;

    td.actions {
        min-width: 100px;
    }
}

.form-alert-text {
    color: salmon;
}

ol.boxes li {
    margin-bottom: 6px;
    padding: 10px;
    border: 1px solid #eee;
}

.bday_select {
    width: 20%;
    float: left;
    margin-right: 8px;
}

.help-block.error {
    color: salmon;
}

.help-block.error:before {
    content: "\f12a";
    font-family: "FontAwesome";
}

label.required:before {
    content: "* ";
    color: salmon;
}

.terms__label {
    margin-bottom: rem(10) !important;
}

.modal__terms {
    width:100%;
    height: auto;
    border: 1px solid #ccc;
    background: #f2f2f2;
    padding: 10px;
    margin-bottom: rem(25);
    font-size: 12px !important;
}

div.terms {
    width:100%;
    height: 250px;
    border:1px solid #ccc;
    background:#f2f2f2;
    padding: 10px;
    overflow: auto;
    margin-bottom: rem(25);
    font-size: 12px !important;
}
div.terms p,
div.terms li {font:normal 13px arial;color:#333;}
div.terms h3 {font:bold 14px/19px arial;color:#000;}
div.terms h4 {font:bold 12px/17px arial;color:#000;}
div.terms strong {color:#000;}
div.terms.small { height: auto; }
div.terms.full { width: 100%; }
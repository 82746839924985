// Content Blocks
//
// These components are used to build project content
//
// Markup: content-blocks.html
//
// Styleguide Components.Content Blocks

.step {

	a {
		color: $green;

		&:hover {
			color: $green;
			text-decoration: underline;
		}
	}

	p {

	}

	ul,ol {
		margin-bottom: rem(45);

		li {
			margin-bottom: rem(10);
		}
	}

	ol {
		list-style-type: decimal;
		margin-left: rem(20);
	}

	h2 {
		font-size: rem(24);
		margin-bottom: rem(30);
		clear: both;
		width: 100%;
		display: inline-block;
	}

	h3 {
		font-size: rem(17);
		margin-bottom: rem(15);
	}

	/***** IMAGES *****/

	.image-wrap {
		text-align: center;
	}

	.cbImage {
		@include margin (0 auto 40px auto);

		img {
			display: block;
			margin: 0 auto;
		}

		&.full {

		}

		&.left {
			float: left;
			margin-right: rem(25);
		}

		&.right {
			float: right;
			margin-left: rem(25);
		}

		&.small {
			width: 50%;
		}

		&.medium {
			width: 75%;
		}

		&.automatic {
			display: inline-block;
		}

		.caption {
			background: $red;
			color: $white;
			font-size: rem(18);
            line-height: rem(22);
			padding: rem(14);
		}
	}

	/***** VIDEO *****/

	.cbVideo {
		margin-bottom: rem(40);
	}

	/***** CODE *****/

	.cbCode {
		margin-bottom: rem(40);
	}

	/***** CODE *****/

	.cbEmbed {
		margin-bottom: rem(40);
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        overflow: hidden;

        iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        object {
            margin-bottom: -50%;
        }
    }

}

.language-none {
    white-space: pre-wrap !important;
}

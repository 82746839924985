// Footer
//
// Site footer
//
// Markup: footer.html
//
// Styleguide Components.Footer

footer {
	background: $black;
	color: #7f7f82;
	padding: rem(40) rem(16) rem(70) rem(16);
	@include media($large-screen) {
		padding: rem(45) 0 rem(41) 0;
		margin-top: rem(50);
	}

	a {
		text-decoration: none;
		color: #7f7f82;
	}

	.footer-content {
		@include row();

		.branding {
			@include span-columns(12);
			border-bottom: 1px solid #4A4A4A;
			padding-bottom: rem(40);
			margin-bottom: rem(30);

			@include media($medium-screen-up) {
				@include span-columns(7 of 12);
				padding-bottom: 0;
				border-bottom: none;
				margin-bottom: 0;
			}

			@include media($large-screen-up) {
				@include span-columns(8);
				@include shift(1);
				padding-bottom: 0;
				border-bottom: none;
			}

			.top-row {
				@include row();

				.logo {
					margin-bottom: rem(40);
					@include span-columns(8 of 8);
					@include media($large-screen-up) {
						@include span-columns(2 of 8);
					}


					img {
						vertical-align: middle;
						width: 190px;
					}
				}

				.footer-nav {
					@include span-columns(8 of 8);
					@include media($large-screen-up) {
						@include span-columns(6 of 8);
					}

					ul {
						margin-bottom: rem(30);
						@include media($large-screen-up) {
							padding: rem(5px) 0 0 rem(25);
						}
						li {
							margin-bottom: rem(10);
							@include media($medium-screen-up) {
								display: inline;
								padding-right: rem(25);
								margin-bottom: 0;
							}

							&:last-child {
								padding-right: 0;

							}
						}
					}
				}
			}
		}

		.legal {
			font-size: rem(14);
		}

		.affiliate {
			@include span-columns(12);

			@include media($medium-screen-up) {
				text-align: right;
				@include span-columns(5);
			}

			@include media($large-screen-up) {
				@include span-columns(4);
			}

			img {
				margin-right: rem(9);
				vertical-align: middle;
			}
		}
	}

}


footer {
    &.home__footer {
        @include row();
        margin: 0;

        .footer__support-row {
            float: left;
            margin-bottom: rem(30);
        }

        .footer__support {

            padding-left: rem(20);
            padding-right: rem(20);
            width: 100%;

            @include media($medium-screen-up) {
                @include shift(1);
                @include span-columns(7);
            }

            .footer__support-icon {
                float: left;
                margin-bottom: rem(12);
                width: rem(130);

                @include media($medium-screen-up) {
                    margin-bottom: 0;
                }
            }

            .footer__support-content {
                float: left;

                h2 {
                    color: $mouse-white;
                    font-size: rem(21);
                    line-height: rem(32);
                    letter-spacing: rem(0.36);
                    margin-bottom: rem(15);

                    @include media($medium-screen-up) {
                        font-size: rem(32);
                    }
                }

                p {
                    color: $mouse-white;
                    font-size: rem(18);
                    line-height: rem(24);
                    letter-spacing: rem(0.4);

                    @include media($medium-screen-up) {
                        line-height: rem(32);
                    }
                }

                a {
                    color: $mouse-white;
                    @extend .Basetica-Bold;
                }


            }
        }

        .footer__hr {
            background: #4B4B4B;

            @include media($medium-screen-up) {
                display: none;
            }
        }

        .footer__brand {

            width: 100%;

            @include media($medium-screen-up) {
                float: left;
                display: block;
                width: rem(240);
            }

            .footer__brand-logo {

                margin-bottom: rem(18);

                @include media($medium-screen-up) {
                    margin-bottom: rem(35);
                }
            }

            p {
                color: $mouse-footer-gray;
                font-size: rem(14);
                line-height: rem(18);
            }
        }

        .footer__brand-content {

        }

    }
}
.label-switch {
  $action-color: #00a886 !default;
  $base-background-color: white !default;
  $switch-width: 54px;
  $switch-padding: 2px;
  $switch-height: 26px;
  $switch-radius: $switch-height;
  $knob-size: 19px;
  $knob-radius: $switch-height - ($switch-padding * 2);
  $knob-width: $knob-size;
  $switch-background: $base-background-color;
  $switch-border-background: darken($base-background-color, 9%);
  $switch-shadow: 0 2px 5px transparentize(black, 0.6);

  @extend .Basetica-Regular;
  font-weight: normal;
  border-radius: $switch-radius;
  cursor: pointer;
  display: inline-block;
  height: $switch-height;
  line-height: 28px;
  position: relative;
  width: $switch-width;

  input[type="checkbox"] {
    position: absolute;
    top: 5px;

    + .checkbox {
      background: $mouse-cool-gray;
      border: 0;
      border-radius: $switch-radius;
      cursor: pointer;
      height: $switch-height;
      margin: 0;
      padding: 0;
      position: relative;
      transition: all 0.3s ease;
      width: $switch-width;
      z-index: 0;
      text-align: right;

      .check-label {
        margin-right: rem(6);
        font-size: rem(11);
        text-transform: uppercase;
        color: #ffffff;
      }

      .off {
        display: block;
      }

      .on {
        display: none;
      }

      &::before {
        @include position(absolute, 4px 0 0 4px);

        border-radius: $switch-radius;
        content: "";
        height: $knob-radius;
        transform: scale(1);
        transition: all 0.3s ease;
        width: $switch-width - ($switch-padding * 2);
        z-index: 1;
      }

      &::after {
        @include position(absolute, 4px 0 0 4px);
        @include size($knob-size);
        background: $switch-background;
        border-radius: $knob-radius;
        box-shadow: $switch-shadow;
        content: "";
        transition: all 0.3s ease;
        z-index: 2;
      }
    }

    &:checked {
      + .checkbox {
        background: $action-color;
        text-align: left;

        &::before {
          transform: scale(0);
        }

        .check-label {
          margin-left: rem(8);
          color: $white;
        }

        .off {
          display: none;
        }

        .on {
          display: block;
        }

        &::after {
          left: $switch-width - $knob-width - ($switch-padding) - 2px;
        }
      }
    }
    &:focus {
      + div.checkbox {
        box-shadow: 0 0 3px rgba(0,0,0,.3);
      }
    }
  }
}

// Buttons
//
// The basis for buttons that appear on the site
//
// Markup:
// <button class="{{modifier_class}}">Button Element</button>
// <a href="#" class="button {{modifier_class}}">Button Link</a>
//
// :hover		- when button is hovered over
// :visited		- when the link has been visited
// .expanded	- a 100% width button
//
// Styleguide Components.Buttons

button,
.button,
 {
	background: $green;
	display: inline-block;
	text-align: center;
	line-height: 1;
	cursor: pointer;
	vertical-align: middle;
	color: $white;
	padding: rem(12);
	font-size: rem(12.5);
	border: none;
	margin-bottom: 1rem;
	border-radius: 2px;
	@extend .Basetica-Medium;

	@include media($medium-screen-up) {
		padding: rem(12) rem(40);
		font-size: rem(18);
	}


	&:hover {
		background: #028E6D !important;
		color: $white;
	}

	&:visited {
		color: $white;
		background: $green;
	}

	&.expanded {
		width: 100%;
	}

    &.button--light-green {
        background: $lightgreen;
        color: $green;
    }
}

.block-button {
	margin-bottom: 2rem;
}

.alter {
	font-size: rem(14);
}

.button__modal {
    float: right;
    position: relative;
    background: #ECF9F6;
    border-radius: 3px;
    color: $green;
    padding: .4em .3em .3em .4em;
    font-size: rem(18px);
    margin: 0;
    margin-top: 5px;
    box-shadow: none;
    font-family: Basetica-Medium, sans-serif;
}
.button-evidence {
    padding: rem(12) rem(40) !important;
    font-size: rem(18) !important;

    @media (max-width: 375px) {
        padding: rem(12) !important;
        font-size: rem(12.5) !important;
    }
}

.button-loading {
    background-color: #dbdbdb;
    color: $white;

    &:hover {
        background-color: #dbdbdb;
        color: $white;
    }
}

.back-to__button {
    display: flex;
    margin-bottom: rem(7);
    margin-top: rem(20);
    padding-left: rem(10);

    @include media($large-screen-up) {
        padding-left: 0;
        margin-top: 0;
    }
}

.back-to__text {
    margin-top: rem(-1);
    margin-left: rem(8);
}

.back-to__icon {
    img {
        height: rem(17);
        width: rem(9);
    }
}

button.button__secondary,
.button__secondary {
    font-size: 16px;
    display: inline-block;
    padding: 5px;
    background-color: #E6F6F2;
    color: $green;
    @extend .Basetica-Bold;
    margin-bottom: 0;
    height: auto;
    line-height: normal;
    &:hover {
        background-color: $green;
        color: $white;
    }
}